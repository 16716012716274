import { useState } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { useParams, useNavigate, useLocation } from 'react-router-dom';


const SearchBox = () => {
    const navigate = useNavigate();
    const [keyword, setKeyword] = useState('');

    const submitHandler = (e) => {
        e.preventDefault();
        if (keyword.trim()) {
            // 複数のキーワードをカンマ区切りで検索できるようにする
            const queryKeywords = keyword.trim().split(/\s+/).join('+');
            navigate(`/search/?keyword=${queryKeywords}`);
        } else {
            navigate('/');
        }
    }

    return (
        <Form onSubmit={ submitHandler } className='d-flex'>
            <InputGroup>
                {/* <InputGroup.Text>
                    <FaSearch />
                </InputGroup.Text> */}
                <Button type='submit' className='search-button p-2'>
                    <FaSearch />
                </Button> 
                <Form.Control 
                    type='text' 
                    name='q' 
                    onChange={(e) => setKeyword(e.target.value)} 
                    placeholder='検索' 
                    className='mr-sm-2 ml-sm-5'
                    value={keyword}
                ></Form.Control>
            </InputGroup>
            {/* <Button type='submit' className='p-2 mx-2'>
                Search
            </Button> */}
        </Form>
    )
}

export default SearchBox;