import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Landing from './components/Landing';
import ReactGA from 'react-ga4';

const App = () => {
  const location = useLocation();
  const [landingKey, setLandingKey] = useState(Date.now());

  useEffect(() => {
    // Google Analyticsの初期化
    ReactGA.initialize('G-7XHJP3PL7X'); // ここに自分のトラッキングIDを入力

    // ページビューのトラッキング
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  useEffect(() => {
    // パスが変わるたびにLandingコンポーネントのキーを更新
    setLandingKey(Date.now());
  }, [location.pathname]);

  return (
    <>
      <Header />
      {location.pathname === '/' && <Landing key={landingKey} />}
      <main className='py-3'>
        <Outlet />
      </main>
      <Footer />
      <ToastContainer />
    </>
  )
};

export default App;
