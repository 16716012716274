import React from 'react';
import { Image, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Paginate from '../Paginate';

const GridDisplay = ({ productsData }) => (
    <>
        <div className="grid">
            {productsData.products.map(product => (
                <div key={product.id} className='grid-item'>
                    <Link className='product-img' to={`/product/${product.id}`}>
                        <Image src={product.image} alt={product.name} fluid />
                        <div className="grid-info">
                            <b className='product-title'>{product.name}</b>
                            {/* <div className='product-caption'>{CATEGORIES[product.category]}</div> */}

                            <div className='product-price-wrapper'>
                                <div className='product-price'>
                                    ¥{product.price}
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
        <Paginate
            pages={productsData.meta.pages}
            page={productsData.meta.page} 
        />
    </>
);

export default GridDisplay;
