export const GENDER = {
    1: 'ユニセックス',
    2: 'メンズ',
    3: 'レディース',
    4: 'キッズ'
};

export const SIZE = {
    1: 'フリーサイズ',
    2: 'XXS',
    3: 'XS',
    4: 'S',
    5: 'M',
    6: 'L',
    7: 'XL',
    8: 'XXL',
    9: 'XXXL'
};

export const STATUS = {
    1: 'sold',
    2: 'rented',
    3: 'available'
};

export const COLOR = {
    1: 'ホワイト',
    2: 'ブラック',
    3: 'グレー',
    4: 'ブラウン',
    5: 'ベージュ',
    6: 'グリーン',
    7: 'ブルー',
    8: 'パープル',
    9: 'イエロー',
    10: 'ピンク',
    11: 'レッド',
    12: 'オレンジ',
    13: 'シルバー',
    14: 'ゴールド',
    15: 'その他'
};


export const GENDER_TO_ID = {
    'men': 2,
    'women': 3,
    'kids': 4,
    'ユニセックス': 1, // コメントアウトされているのは、オリジナルのコメントに従います
};

export const SIZE_TO_ID = {
    'フリーサイズ': 1,
    'XXS': 2,
    'XS': 3,
    'S': 4,
    'M': 5,
    'L': 6,
    'XL': 7,
    'XXL': 8,
    'XXXL': 9,
};

export const STATUS_TO_ID = {
    'sold': 1,
    'rented': 2,
    'available': 3,
};

export const COLOR_TO_ID = {
    'ホワイト': 1,
    'ブラック': 2,
    'グレー': 3,
    'ブラウン': 4,
    'ベージュ': 5,
    'グリーン': 6,
    'ブルー': 7,
    'パープル': 8,
    'イエロー': 9,
    'ピンク': 10,
    'レッド': 11,
    'オレンジ': 12,
    'シルバー': 13,
    'ゴールド': 14,
    'その他': 15,
};

const TOPS_MEASUREMENTS = {
    measurements: ['length', 'shoulder-width', 'width', 'sleeve']
};

export const MEASUREMENTS = {
    1: TOPS_MEASUREMENTS,
    2: TOPS_MEASUREMENTS,
    3: { // パンツ
        measurements: ['waist-inch', 'length-inch', 'waist', 'hip', 'rise', 'inseam', 'thigh', 'leg-opening']
    },
    4: { // オールインワン
        measurements: ['total-length', 'waist', 'rise', 'inseam', 'leg-opening']
    },
    5: { // スカート
        measurements: ['waist', 'hip', 'skirt-length',]
    },
    6: { // ワンピース
        measurements: ['length', 'shoulder-width', 'width', 'waist', 'sleeve', 'skirts-length']
    },
};

export const MEASUREMENTS_MAPPING = {
    'length': '着丈(cm)',
    'shoulder-width': '肩幅(cm)',
    'width': '身幅(cm)',
    'sleeve': 'そで丈(cm)',

    'waist-inch': '表記ウエスト(インチ)',
    'length-inch': '表記レングス(インチ)',
    'waist': 'ウエスト(cm)',
    'hip': 'ヒップ(cm)',
    'rise': '股上(cm)',
    'inseam': '股下(cm)',
    'thigh': 'もも周り/わたり(cm)',
    'leg-opening': 'すそ幅(cm)',

    'total-length': '総丈(cm)',

    'skirt-length': 'スカート丈(cm)',
};