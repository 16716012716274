import { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useGetShopByIdQuery } from '../slices/shopsApiSlice';
import { FaMapMarkerAlt } from 'react-icons/fa';
import Loader from './Loader';
import Message from './Message';
import { formatDateRange2 } from '../utils/dateUtils';
import { usePurchaseRentalProductMutation } from '../slices/ordersApiSlice';

const ShopInfoModal = ({ show, shopId, startDate, endDate, daysLeft, orderId, productId, productPrice, onHide }) => {
    const navigate = useNavigate();
    const { data: shop, error, isLoading, refetch } = useGetShopByIdQuery(shopId, {
        skip: !show, // モーダルが表示されるときだけクエリを実行
    });

    const [purchaseRentalProduct, { isLoading: isPurchasing }] = usePurchaseRentalProductMutation();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isMainModalVisible, setIsMainModalVisible] = useState(true);


    useEffect(() => {
        if (show) {
            refetch(); // モーダルが表示されたときにデータを再取得
        }
    }, [show, refetch]);

    const handlePurchase = async () => {
        try {
            const res = await purchaseRentalProduct({ productId, orderId }).unwrap();
            navigate(`/order/${res.order.id}`);
        } catch (err) {
            console.error(err);
        }
    }

    const handleConfirmPurchase = () => {
        setIsMainModalVisible(false);
        setShowConfirmModal(true);
    };

    const handleConfirmClose = () => {
        setShowConfirmModal(false);
        setIsMainModalVisible(true);
    };
    

    const discountedPrice = productPrice * 0.9;

    return (<>
        <Modal show={isMainModalVisible && show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {daysLeft !== null && daysLeft !== undefined ? (
                        daysLeft >= 0 ? (
                            <>
                                <small>返却期限:</small> <strong>あと{daysLeft}日</strong>
                            </>
                        ) : (
                            <>
                                <small>返却期限:</small> <strong>期限切れ ({Math.abs(daysLeft)}日前)</strong>
                            </>
                        )
                    ) : isLoading ? (
                        <Loader />
                    ) : error ? (
                        <Message variant="danger">{error?.data?.message || error.error}</Message>
                    ) : shop ? (
                        <>
                            <small>店名:</small> <strong>{shop.name}</strong>
                        </>
                    ) : (
                        <>情報がありません</>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? (
                    <Loader />
                ) : error ? (
                    <Message variant="danger">{error?.data?.message || error.error}</Message>
                ) : shop ? (
                    <div>
                        {daysLeft !== null && daysLeft !== undefined && (
                            <p><strong>店名:</strong> {shop.name}</p>
                        )}
                        <p><strong>住所:</strong> 〒{shop.postalCode} {shop.prefecture} {shop.city} {shop.streetAddress} {shop.buildingName} </p>
                        <p><strong>電話番号:</strong> {shop.phoneNumber}</p>
                        {shop.googleMapsLink && (
                            <p>
                                <strong>地図: </strong> 
                                <a 
                                    href={shop.googleMapsLink} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    style={{ color: '#007bff', textDecoration: 'underline', fontWeight: 'bold' }}
                                >
                                    <FaMapMarkerAlt/> Googleマップで見る
                                </a>
                            </p>
                        )}

                        {startDate && endDate && (
                            <p><strong>レンタル期間:</strong> {formatDateRange2(startDate, endDate)}</p>
                        )}
                        {orderId && productId && (
                            <Button variant="success" 
                                onClick={handleConfirmPurchase} disabled={isPurchasing}
                            >
                                10%オフで購入
                            </Button>
                        )}
                    </div>
                ) : (
                    <p>情報がありません</p>
                )}
            </Modal.Body>
        </Modal>
        { productPrice && (
            <Modal show={showConfirmModal} onHide={handleConfirmClose}>
                <Modal.Header closeButton>
                    <Modal.Title>購入確認</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>元の金額: {productPrice.toLocaleString()}円</p>
                    <p>10%オフの金額: {discountedPrice.toLocaleString()}円</p>
                    <p>この商品を購入しますか？</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleConfirmClose}>
                        キャンセル
                    </Button>
                    <Button variant="success" onClick={handlePurchase} disabled={isPurchasing}>
                        購入
                    </Button>
                </Modal.Footer>
            </Modal>
        )}
    </>);
};

export default ShopInfoModal;
