import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { CATEGORIES } from '../constants/categories';

const ShopProduct = React.memo(({ product: { id, image, name, category, price, status, shopName, serviceOption = null}, onDelete, isAdmin = false }) => {
    // 削除ボタンのクリックイベントハンドラを修正
    const handleDeleteClick = (e) => {
        e.stopPropagation(); // リンクのイベント伝播を停止
        onDelete(id);
    };

    return (
        <div className='product rounded'>
            <Link to={`/shop/product/${id}/edit`} className='product-link'>
                <div className='product-img'>
                    <img src={image} alt={name} loading='lazy' />
                </div>

                <div className='product-body'>
                    <b className='product-title'>{isAdmin ? shopName : name}</b>
                    <div className='product-caption'>
                        {serviceOption === 'rental' ? (
                            <Badge bg='warning'>レンタル</Badge>
                        ) : serviceOption === 'purchase' ? (
                            <Badge bg='info'>購入</Badge>
                        ) : category ? category.name
                        : null
                    }
                    </div>

                    <div className='product-price-wrapper'>
                        <div className='product-price'>
                            ¥{price}
                        </div>
                    </div>
                </div>
            </Link>
            {(status === 'available' || isAdmin) && (
                <Button
                    className='btn-sm product-delete' 
                    onClick={handleDeleteClick}
                >
                    <FaTimes />
                </Button>
            )}
        </div>
    );
});

export default ShopProduct;