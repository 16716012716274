import { useState } from 'react';
import { Button, Image, Row, Col } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const ImageGallery = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextImage = () => {
        setCurrentIndex((currentIndex + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentIndex((currentIndex - 1 + images.length) % images.length);
    };

    const handleThumbnailClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div>
            <Row className='mb-4 justify-content-center'>
                <Col md={10} className='d-flex justify-content-center position-relative'>
                    {/* <Button variant="light" onClick={prevImage}>&lt;</Button> */}
                    <button onClick={prevImage} className="scroll-button scroll-left mx-2">
                        <FaChevronLeft className='scroll-svg' />
                    </button>
                    <div className="image-container">
                        <Image src={images[currentIndex]} alt={`Image ${currentIndex}`} fluid />
                    </div>
                    <button onClick={nextImage} className="scroll-button scroll-right mx-2">
                        <FaChevronRight className='scroll-svg' />
                    </button>
                    {/* <Button variant="light" onClick={nextImage}>&gt;</Button> */}
                </Col>
            </Row>
            <Row className='justify-content-center'>
                {/* <Col md={10} className='d-flex justify-content-center'> */}
                <Col md={10} className='image-grid'>
                    {images.map((image, index) => (
                        <div key={index} className="thumbnail-container">
                            <Image 
                                src={image} 
                                alt={`Thumbnail ${index}`} 
                                onClick={() => handleThumbnailClick(index)} 
                                style={{ cursor: 'pointer', border: index === currentIndex ? '2px solid #7b8a8b' : '' }}
                                fluid
                            />
                        </div>
                    ))}
                </Col>
            </Row>
        </div>
    );
};

export default ImageGallery;
