import React from 'react';
import { Modal, Button, Badge } from 'react-bootstrap';
import { useGetOrderShippingAddressQuery, useDeliverOrderMutation, useReturnRentalProductMutation } from '../slices/ordersApiSlice';
import { toast } from 'react-toastify';
import { formatDateRange2 } from '../utils/dateUtils';

const OrderDetailsModal = ({ show, onHide, order, products, isRental, refetch }) => {
    const { data: Info, isFetching, error } = useGetOrderShippingAddressQuery(order);
    const [deliverOrder, { isLoading: isDelivering, reset: resetDeliver }] = useDeliverOrderMutation();
    const [returnRentalProduct, { isLoading: isReturning, reset: resetReturn }] = useReturnRentalProductMutation();

    const handleDeliverAllProducts = async () => {
        if (!Info?.isPaid) {
            toast.error("支払いが完了していないため、商品の配送はできません。");
            return;
        }
        try {
            await deliverOrder({
                orderId: order,
                purchaseItemIds: products.filter(p => p.serviceOption === 'purchase').map(p => p.id),
                rentalItemIds: products.filter(p => p.serviceOption === 'rental').map(p => p.id)
            }).unwrap();
            toast.success("商品の配送が完了しました");
            refetch();
            onHide();
            resetDeliver(); // 配送ミューテーションの状態をリセット
        } catch (error) {
            toast.error("商品の配送処理に失敗しました。サポートにお問い合わせください。");
        }
    };

    const handleReturnProduct = async () => {
        try {
            await returnRentalProduct(products[0].id).unwrap();
            toast.success("商品の返却が完了しました");
            refetch();
            onHide();
            resetReturn(); // 返却ミューテーションの状態をリセット
        } catch (error) {
            toast.error("商品の返却処理に失敗しました。サポートにお問い合わせください。");
        }
    };

    const inStoreItems = [
        ...products.filter(item => item.inStore) || [],
    ];
    const deliveryItems = [
        ...products.filter(item => !item.inStore) || [],
    ];

    return (
        <Modal show={show} onHide={onHide} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    Order ID: {order}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>お客様の情報</h4>
                {isFetching ? (
                    <div>Loading...</div>
                ) : error ? (
                    <div>Error: {error.message}</div>
                ) : !Info?.isPaid ? (
                    <div className="text-center text-danger">
                        <h4>支払いが完了していません</h4>
                        <p>オーダー確定から12時間以内に支払いがない場合は、自動的にオーダーがキャンセルとなります。</p>
                    </div>
                ) : (
                    <div className='px-2 pb-3'>
                        <p>名前: {Info?.name}</p>
                        <p>〒: {Info?.shippingAddress?.postalCode}</p>
                        <p>住所: {Info?.shippingAddress?.prefecture} {Info?.shippingAddress?.city} {Info?.shippingAddress?.streetAddress}</p>
                        <p>{Info?.shippingAddress?.buildingName}</p>
                    </div>
                )}
                {inStoreItems.length > 0 && (
                    <>
                        <h4>お店でお渡し</h4>
                        <ul className='px-2'>
                            {inStoreItems.map(item => (
                                <li key={item.name}>
                                    {item.name} - {item.startDate && item.endDate ? (
                                        <>
                                            ({formatDateRange2(item.startDate, item.endDate)})
                                            <Badge bg="warning" className="ms-2">レンタル</Badge>
                                        </>
                                    ) : item.useSubscription ? (<>
                                        <Badge bg="warning" className="ms-2">レンタル</Badge>
                                        <Badge bg="success" className="ms-2">サブスク</Badge>
                                    </>) : (
                                        <Badge bg="info">購入</Badge>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                {deliveryItems.length > 0 && (
                    <>
                        <h4>配送</h4>
                        <ul className='px-2'>
                            {deliveryItems.map(item => (
                                <li key={item.name}>
                                    {item.name} - {item.startDate && item.endDate ? (
                                        <>
                                            ({formatDateRange2(item.startDate, item.endDate)})
                                            <Badge bg="warning" className="ms-2">レンタル</Badge>
                                        </>
                                    ) : item.useSubscription ? (<>
                                        <Badge bg="warning" className="ms-2">レンタル</Badge>
                                        <Badge bg="success" className="ms-2">サブスク</Badge>
                                    </>) : (
                                        <Badge bg="info">購入</Badge>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                {isRental ? (
                    <Button onClick={handleReturnProduct} disabled={isReturning}>
                        返却を完了
                    </Button>
                ) : (
                    <Button onClick={handleDeliverAllProducts} disabled={isDelivering || !Info?.isPaid}>
                        配送完了
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default OrderDetailsModal;