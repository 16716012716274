import { useState, useMemo} from 'react';
import { Button, Container, ListGroup, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { 
    useGetMyFavoritesQuery,
    useDeleteFavoriteProductMutation,
    useDeleteFavoriteShopMutation,
} from '../slices/favoritesApiSlice';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Tabs from '../components/Tabs';
import FavoriteProduct from '../components/FavoriteProduct';
import { Link } from 'react-router-dom';

const FavoriteScreen = () => {
    const { data: favorites, isLoading, isError, refetch } = useGetMyFavoritesQuery();
    const [deleteFavoriteProduct] = useDeleteFavoriteProductMutation();
    const [deleteFavoriteShop] = useDeleteFavoriteShopMutation();

    const [selectedFavorite, setSelectedFavorite] = useState('products');
    const tabsHandler = (value) => {
        setSelectedFavorite(value);
    };
    const favoriteTabs = useMemo(() => [
        { label: 'アイテム', value: 'products', ariaSelected:  selectedFavorite === 'products' },
        { label: 'ショップ', value: 'shops', ariaSelected:  selectedFavorite === 'shops' },
    ], [selectedFavorite]);

    const deleteHandler = async (id) => {
        if (window.confirm('お気に入りから削除しますか？')) {
            try {
                await deleteFavoriteProduct(id);
                toast.success('お気に入りから削除しました');
                refetch();
            } catch (err) {
                toast.error('操作に失敗しました。もう一度お試しください。');
            }
        }
    };

    if (isLoading) return <div>Loading...</div>;
    if (isError || !favorites) return <div>Error occurred...</div>;

    return (
        <Container>
            <h1>お気に入り</h1>
            <Tabs
                tabs={favoriteTabs}
                onClick={(value) => tabsHandler(value)}
            />
    
            {isLoading ? <Loader /> 
                : isError ? <Message variant='danger'>{isError.data?.message}</Message> 
                : selectedFavorite === 'products' ? 
                    <div className='product-list'>
                        {favorites.products.map((product) => (
                            <li key={product.id}>
                                <FavoriteProduct product={product} onDelete={deleteHandler} />
                            </li>
                        ))}
                    </div>
                : selectedFavorite === 'shops' && (
                    <div className='shop-list'>
                        {favorites.shops.map((shop) => (
                            <Link key={shop.id} to={`/shop/${shop.slug}`}>
                                <div className="shop-card">
                                    <img className="shop-logo" alt={`Logo of ${shop.name}`} src={shop.logo} />
                                    <div className="shop-name">
                                        <b>{shop.name}</b>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                )
            }
        </Container>
    );
};

export default FavoriteScreen;