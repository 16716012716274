import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Form, Row, Col, ListGroup, Card, Button, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import ImageGallery from '../components/ImageGallery';
import FavoriteButton from '../components/FavoriteButton';
import Meta from '../components/Meta';
import { 
    useGetProductDetailsQuery,
} from '../slices/productsApiSlice';
import { useCreateReviewMutation } from '../slices/shopsApiSlice';
import { addToCart } from '../slices/cartSlice';
import { SIZE } from '../constants/productConstants';

const ProductScreen = () => {

    const { id: productId } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [serviceOption, setServiceOption] = useState('rent');
    const [useSubscription, setUseSubscription] = useState(false);

    const [rentalPeriod, setRentalPeriod] = useState('4days'); // デフォルトのレンタル期間を設定

    const [inStore, setInStore] = useState(true); // デフォルトは店舗を想定

    // const [rating, setRating] = useState(0);
    // const [comment, setComment] = useState('');

    const { data: product, isLoading, refetch, error } = useGetProductDetailsQuery(productId);

    // const [createReview, { isLoading: loadingShopReview  }] = useCreateReviewMutation();

    const { userInfo } = useSelector((state) => state.auth);

    // サブスクリプション使用条件をチェック
    const canUseSubscription = userInfo && userInfo.subscription &&
                               userInfo.subscription.usedItems < userInfo.subscription.maxItems &&
                               product?.price <= userInfo.subscription.itemPriceLimit;

    // サブスクリプション使用不可の理由
    let subscriptionUnavailableReason = '';
    if (!userInfo || !userInfo.subscription) {
        subscriptionUnavailableReason = (
            <>
                <small className="small-text">
                    サブスクリプションプランに加入して、さらにお得にレンタルを楽しむことができます。<br />
                    <Link to="/subscription" className='text-warning'>こちら</Link>から加入をご検討ください。
                </small>
            </>
        );
    } else if (userInfo && userInfo.subscription) {
        if (userInfo.subscription.usedItems >= userInfo.subscription.maxItems) {
            subscriptionUnavailableReason = (
                <>
                    <small className="small-text">
                        今月のサブスク利用回数を使い切りました。
                    </small>
                </>
            );
        } else if (product?.price > userInfo.subscription.itemPriceLimit) {
            subscriptionUnavailableReason = (
                <>
                    <small className="small-text">
                        選択した商品の価格が現在のサブスクリプションプランの予算を超えています。<br />
                        <Link to="/subscription/upgrade">プランをアップグレード</Link>して、さらに多くの商品をお得にレンタルしましょう。
                    </small>
                </>
            );
        }
    }

    const calculatePrice = (price, serviceOption, useSubscription, userInfo, rentalPeriod) => {
        if (serviceOption === 'rent') {
            if (useSubscription && userInfo && userInfo.subscription) {
                return 0; // サブスクリプションを使用する場合は無料
            } else if (!useSubscription && userInfo && userInfo.organization) {
                switch (rentalPeriod) {
                    case '4days':
                        return price * 0.35; // 3泊4日
                    case '2weeks':
                        return price * 0.45; // 2週間
                    case '1month':
                        return price * 0.55; // 一ヶ月
                    default:
                        return price * 0.35; // デフォルトは3泊4日
                }
            } else if (!useSubscription) {
                switch (rentalPeriod) {
                    case '4days':
                        return price * 0.4; // 3泊4日
                    case '2weeks':
                        return price * 0.5; // 2週間
                    case '1month':
                        return price * 0.6; // 一ヶ月
                    default:
                        return price * 0.4; // デフォルトは3泊4日
                }
            }
        }
        return price; // 購入の場合はそのままの価格
    };
    

    const addToCartHandler = () => {
        const finalPrice = calculatePrice(product.price, serviceOption, useSubscription, userInfo, rentalPeriod);

        const itemToAdd = {
            ...product,
            serviceOption,
            inStore,
            useSubscription: canUseSubscription && useSubscription, // サブスクリプションの条件を満たしている場合のみ使用する
            rentalPeriod: !useSubscription ? rentalPeriod : 'unlimited', // サブスクリプションを使用する場合は無制限
            finalPrice,
        };
        dispatch(addToCart(itemToAdd));
        navigate('/cart');
    }

    // const submitHandler = async (e) => {
    //     e.preventDefault();
    //     try {
    //         await createReview({
    //             shopId: product.shopId,
    //             rating,
    //             comment,
    //         }).unwrap()
    //         refetch();
    //         toast.success('Review Submitted');
    //         setRating(0);
    //         setComment('');
    //     } catch (err) {
    //         toast.error(err?.data?.message || err.error);
    //     }
    // }

    // productのstatusが在庫有りでない時は、サービスオプションを購入に設定
    useEffect(() => {
        if (product && product.status !== 'available') {
            setServiceOption('buy');
        }
    }, [product]);
    

    return (
        <Container>
            {/* <Link className="btn btn-light my-3" to='/'>
                Go Back
            </Link> */}
            {
                isLoading ? (<h2>Loading...</h2>)
                : error ? (<Message variant='danger'> { error?.data?.message || error.error } </Message>) 
                    : product ? (
                        <>
                            <Meta title={product.name} />
                            <Row className='mt-5'>
                                <Col md={5}>
                                    <ImageGallery images={[product.image, ...(product.subImages || [])]} />
                                </Col>
                                <Col md={4}>
                                    <ListGroup variant='flush'>
                                        <ListGroup.Item>
                                            <h3>{product.name}</h3>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <FavoriteButton itemId={productId} isProduct={true} />
                                            {/* <Rating shop={`【${product.shop.name}】`} value={product.rating} text={`${product.numReviews} reviews`} /> */}
                                        </ListGroup.Item>
                                        <ListGroup.Item>販売価格: {product.price}円(税込み)</ListGroup.Item>
                                        <ListGroup.Item>商品説明: {product.description}</ListGroup.Item>
                                        <ListGroup.Item>サイズ: {SIZE[product.size]}</ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col md={3}>
                                    <Card>
                                        <ListGroup variant='flush'>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col>小計:</Col>
                                                    <Col>
                                                        <strong>{calculatePrice(product.price, serviceOption, useSubscription, userInfo, rentalPeriod)}円</strong>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col>在庫状況:</Col>
                                                    <Col>
                                                        <strong>
                                                            {
                                                                product.status === 'available' ? '◯' 
                                                                : product.status === 'rented' ? 'Rented'
                                                                : '品切れ中'
                                                            }
                                                        </strong>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>

                                            {product.status === 'available' && (
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col>オーダー</Col>
                                                        <Col>
                                                            <Form.Control
                                                                as="select"
                                                                value={serviceOption}
                                                                onChange={(e) => setServiceOption(e.target.value)}
                                                            >
                                                                <option value="rent">レンタル</option>
                                                                <option value="buy">購入</option>
                                                            </Form.Control>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            )}

                                            {product.status === 'available' && serviceOption === 'rent' && (
                                                <ListGroup.Item>
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="サブスクを使用する"
                                                        checked={useSubscription}
                                                        onChange={(e) => setUseSubscription(e.target.checked)}
                                                        disabled={!canUseSubscription}
                                                    />
                                                    {subscriptionUnavailableReason} <br />
                                                        {userInfo && userInfo.subscription ? (
                                                            <small>
                                                                <strong>サブスクリプションプラン:</strong> {userInfo.subscription.planName}
                                                                <br />
                                                                <strong>現在レンタルしている数:</strong> {userInfo.subscription.usedItems}/{userInfo.subscription.maxItems}
                                                                <br />
                                                                <strong>商品価格上限:</strong> ¥{userInfo.subscription.itemPriceLimit}
                                                            </small>
                                                        ) : null}
                                                </ListGroup.Item>
                                            )}

                                            {serviceOption === 'rent' && !useSubscription && (
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col>
                                                            <Form.Label>レンタル期間：</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control
                                                                as="select"
                                                                value={rentalPeriod}
                                                                onChange={(e) => setRentalPeriod(e.target.value)}
                                                            >
                                                                {userInfo && userInfo.organization ? (
                                                                    <>
                                                                        <option value="4days">3泊4日 - 35%</option>
                                                                        <option value="2weeks">2週間 - 45%</option>
                                                                        <option value="1month">1ヶ月 - 55%</option>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <option value="4days">3泊4日 - 40%</option>
                                                                        <option value="2weeks">2週間 - 50%</option>
                                                                        <option value="1month">1ヶ月 - 60%</option>
                                                                    </>
                                                                )}
                                                            </Form.Control>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            )}

                                            {product.status === 'available' && (
                                                <ListGroup.Item>
                                                    <Form.Check
                                                        type="checkbox"
                                                        label={`店舗で受け取る（${product.shopArea?.name}）`} // エリア名を直接使用
                                                        checked={inStore}
                                                        onChange={(e) => setInStore(e.target.checked)}
                                                    >
                                                    </Form.Check>
                                                    <small className="small-text">
                                                        受け取りで、送料（880円）がかかりません。
                                                    </small>
                                                </ListGroup.Item>
                                            )}

                                            <ListGroup.Item>
                                                <Button 
                                                    className='btn-block' 
                                                    type='button' 
                                                    disabled={product.status !== 'available'}
                                                    onClick={addToCartHandler}
                                                >
                                                    カートに追加
                                                </Button>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card>
                                    {serviceOption === 'rent' && !useSubscription && userInfo?.organization && (
                                        <Row className='mt-3 mx-1'>
                                            <Message variant="success">メンバー限定割引が適用されています！</Message>
                                        </Row>
                                    )}
                                </Col>
                            </Row>

                            {/* <Row className='review'>
                                <Col md={6}>
                                    <h2>【Shop Reviews】 {product.shopName} {product.shopArea}</h2>
                                    {product.shop.reviews.length === 0 && <Message>レビューを書いてみましょう</Message>}
                                    <ListGroup variant='flush'>
                                        {product.shop.reviews.map((review) => (
                                            <ListGroup.Item key={review.id}>
                                                <strong>{review.name}</strong>
                                                <Rating value={review.rating} />
                                                <p>{review.createdAt.substring(0, 10)}</p>
                                                <p>{review.comment}</p>
                                            </ListGroup.Item>
                                        ))}
                                        <ListGroup.Item>
                                            <h2>レビューを書く</h2>

                                            {loadingShopReview && <Loader />}

                                            {userInfo ? (
                                                <Form onSubmit={ submitHandler }>
                                                    <Form.Group controlId='rating' className='my-2'>
                                                        <Form.Label>評価</Form.Label>
                                                        <Form.Control
                                                            as='select'
                                                            value={rating}
                                                            onChange={(e) => setRating(Number(e.target.value))}
                                                        >
                                                            <option value=''>Select...</option>
                                                            <option value='1'>1</option>
                                                            <option value='2'>2</option>
                                                            <option value='3'>3</option>
                                                            <option value='4'>4</option>
                                                            <option value='5'>5</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group controlId='comment' className='my-2'>
                                                        <Form.Label>コメント</Form.Label>
                                                        <Form.Control
                                                            as='textarea'
                                                            row='3'
                                                            value={comment}
                                                            onChange={(e) => setComment(e.target.value)}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                    <Button
                                                        disabled={loadingShopReview}
                                                        type='submit'
                                                        variant='primary'
                                                    >
                                                        Submit
                                                    </Button>
                                                </Form>
                                            ) : (
                                                <Message>
                                                    レビューを書くためには <Link to='/login'>sign in</Link> が必要です{' '}
                                                </Message>
                                            )}
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row> */}
                        </>
                ) : <Message variant='info'>商品が見つかりません</Message>
            }
        </Container>
    )
}

export default ProductScreen;


// useSelector と useState の違い
// スコープ: useSelectorはグローバルなReduxストアの状態を扱うのに対し、useStateはローカルなコンポーネントの状態を扱います。
// データの共有: useSelectorで取得したデータはアプリケーション全体で共有される可能性がありますが、useStateのデータはそのコンポーネント内でのみ利用されます。
// 用途: useSelectorは主にデータの読み取りに使用され、useStateはコンポーネントの内部ロジックやUIの状態を管理するために使用されます。