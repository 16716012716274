import { PARENT_CATEGORIES } from '../constants/categories';
import { Link } from 'react-router-dom';
import CategoryPopup from './CategoryPopup';
import { useGetCategoryTreeQuery } from '../slices/categoriesApiSlice';

const HomeNavSidebar = () => {
  const { data: categoryTree } = useGetCategoryTreeQuery();

  return (
    <nav>
      <div className='home-left-title'>
        <h2>探す</h2>
      </div>
      <ul className='home-left-ul'>
        <li>
          <Link to='/search'>全商品から探す</Link>
        </li>
        {/* <li>
          <Link to='/area'>地域から探す</Link>
        </li> */}
        <li>
          <Link to='/shops'>店舗から探す</Link>
        </li>
        {/* <li>
          <Link to='/brand'>ブランドから探す</Link>
        </li>
        <li>
          <Link to='/detail'>こだわり条件から探す</Link>
        </li> */}
      </ul>
      <section className='category-search'>
        <div className='category-search-title'>
          <h3>カテゴリーから探す</h3>
        </div>
        <ul>
          {categoryTree &&
            categoryTree.map((parentCategory) => (
              <CategoryPopup 
                key={parentCategory.id} 
                parentCategory={parentCategory} 
              />
            ))}
        </ul>
      </section>
    </nav>
  );
};

export default HomeNavSidebar;