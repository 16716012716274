import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';

const ShopForm = ({ show, handleClose, onSubmit, shop, areas }) => {
  // 既存のショップフィールド
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [area, setArea] = useState('1');
  const [googleMapsLink, setGoogleMapsLink] = useState('');
  const [description, setDescription] = useState('');
  const [logo, setLogo] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const [openingHours, setOpeningHours] = useState('');
  
  // 追加: ショップオーナー情報フィールド
  const [ownerName, setOwnerName] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');
  const [ownerPassword, setOwnerPassword] = useState('');
  
  const [error, setError] = useState(null);

  useEffect(() => {
    if (shop) {
      // ショップ情報のセット
      setName(shop.name || '');
      setSlug(shop.slug || '');
      setArea(shop.areaId ? shop.areaId.toString() : '1');
      setGoogleMapsLink(shop.googleMapsLink || '');
      setDescription(shop.description || '');
      setLogo(shop.logo || '');
      setCoverImage(shop.coverImage || '');
      setOpeningHours(shop.openingHours || '');
      
      // オーナー情報のセット（既存ショップの場合）
      setOwnerName(shop.ownerName || '');
      setOwnerEmail(shop.ownerEmail || '');
      // パスワードはセキュリティ上、表示しない
      setOwnerPassword('');
    } else {
      // リセット
      setName('');
      setSlug('');
      setArea('1');
      setGoogleMapsLink('');
      setDescription('');
      setLogo('');
      setCoverImage('');
      setOpeningHours('');
      setOwnerName('');
      setOwnerEmail('');
      setOwnerPassword('');
    }
    setError(null);
  }, [shop, show]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // バリデーション: ショップとオーナーの必須フィールド
    if (!name || !slug || !area || !ownerName || !ownerEmail || !ownerPassword) {
      setError('全ての必須フィールドを入力してください。');
      return;
    }

    const shopData = {
      id: shop ? shop.id : undefined,
      name,
      slug,
      areaId: Number(area),
      googleMapsLink,
      description,
      logo,
      coverImage,
      openingHours,
      // オーナー情報を含める
      ownerName,
      ownerEmail,
      ownerPassword,
    };

    onSubmit(shopData);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{shop ? 'ショップ編集' : 'ショップ追加'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group controlId="shopName" className="mb-3">
            <Form.Label>ショップ名</Form.Label>
            <Form.Control
              type="text"
              placeholder="ショップ名を入力"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="shopSlug" className="mb-3">
            <Form.Label>スラッグ</Form.Label>
            <Form.Control
              type="text"
              placeholder="スラッグを入力"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              required
            />
            <Form.Text className="text-muted">
              URLに使用される一意の識別子です。
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="shopArea" className="mb-3">
            <Form.Label>エリア</Form.Label>
            <Form.Select value={area} onChange={(e) => setArea(e.target.value)} required>
              {areas.map((area) => (
                <option key={area.id} value={area.id}>
                  {area.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>


          <Form.Group controlId="googleMapsLink" className="mb-3">
            <Form.Label>Google Mapsリンク</Form.Label>
            <Form.Control
              type="url"
              placeholder="Google Mapsのリンクを入力"
              value={googleMapsLink}
              onChange={(e) => setGoogleMapsLink(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="description" className="mb-3">
            <Form.Label>説明</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="ショップの説明を入力"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="logo" className="mb-3">
            <Form.Label>ロゴURL</Form.Label>
            <Form.Control
              type="url"
              placeholder="ロゴ画像のURLを入力"
              value={logo}
              onChange={(e) => setLogo(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="coverImage" className="mb-3">
            <Form.Label>カバー画像URL</Form.Label>
            <Form.Control
              type="url"
              placeholder="カバー画像のURLを入力"
              value={coverImage}
              onChange={(e) => setCoverImage(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="openingHours" className="mb-3">
            <Form.Label>営業時間</Form.Label>
            <Form.Control
              type="text"
              placeholder="例: 9:00 - 18:00"
              value={openingHours}
              onChange={(e) => setOpeningHours(e.target.value)}
            />
          </Form.Group>
          {/* 追加: ショップオーナー情報のフォームグループ */}
          <Form.Group controlId="ownerName" className="mb-3">
            <Form.Label>オーナー名</Form.Label>
            <Form.Control
              type="text"
              placeholder="オーナー名を入力"
              value={ownerName}
              onChange={(e) => setOwnerName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="ownerEmail" className="mb-3">
            <Form.Label>オーナーのメールアドレス</Form.Label>
            <Form.Control
              type="email"
              placeholder="オーナーのメールアドレスを入力"
              value={ownerEmail}
              onChange={(e) => setOwnerEmail(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="ownerPassword" className="mb-3">
            <Form.Label>オーナーのパスワード</Form.Label>
            <Form.Control
              type="password"
              placeholder="オーナーのパスワードを入力"
              value={ownerPassword}
              onChange={(e) => setOwnerPassword(e.target.value)}
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            キャンセル
          </Button>
          <Button variant="primary" type="submit">
            {shop ? '更新' : '追加'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ShopForm;