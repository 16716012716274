import React, { useState } from 'react';
import { useGetMyBalanceQuery, useCreateWithdrawalMutation } from "../../slices/balanceApiSlice";
import { Container, Table, Spinner, Alert, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import BankAccountModal from '../../components/BankAccountModal';
import CreateWithdrawalModal from '../../components/CreateWithdrawalModal';

const BalanceScreen = () => {
    const { data: balanceData, error, isLoading } = useGetMyBalanceQuery();
    const [createWithdrawal, { isLoading: isCreating }] = useCreateWithdrawalMutation();
    const [showBankAccountModal, setShowBankAccountModal] = useState(false);
    const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);

    const handleBankAccountModalClose = () => setShowBankAccountModal(false);
    const handleBankAccountModalShow = () => setShowBankAccountModal(true);
    const handleWithdrawalModalClose = () => setShowWithdrawalModal(false);
    const handleWithdrawalModalShow = () => setShowWithdrawalModal(true);

    const handleWithdraw = async (amount) => {
        try {
            await createWithdrawal({amount}).unwrap();
            toast.success('引き出し申請が成功しました！');
            handleWithdrawalModalClose();
        } catch (error) {
            toast.error(`引き出し申請に失敗しました: ${error.data?.message || error.message}`);
            console.error('引き出し申請に失敗しました', error);
        }
    };
    

    if (isLoading) {
        return (
            <Container className="text-center">
                <Spinner animation="border" />
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="text-center">
                <Alert variant="danger">
                    {error.message}
                </Alert>
            </Container>
        );
    }

    return (
        <Container>
            <h1>お金管理</h1>
            {balanceData && balanceData.balance ? (
                <div className="container my-5">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-body text-center">
                                    <h5 className="card-title">売上残高</h5>
                                    <h3 className="card-text mb-4">
                                        <b>¥{balanceData.balance.balance}</b>
                                    </h3>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <button
                                                className="btn btn-outline-primary w-100"
                                                onClick={handleWithdrawalModalShow}
                                                disabled={isCreating}
                                            >
                                                振込申請する
                                            </button>
                                            <small className="d-block mt-2">※ 振込申請は一日一回までです</small>
                                        </div>
                                        <div className="col-md-6">
                                            <button
                                                className="btn btn-outline-secondary w-100"
                                                onClick={handleBankAccountModalShow}
                                            >
                                                銀行口座を登録する
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-2'>
                                <small>最終更新日時: {new Date(balanceData.balance.updatedAt).toLocaleString()}</small>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Alert variant="warning">バランス情報が見つかりません。</Alert>
            )}

            <h2>履歴</h2>
            {balanceData && balanceData.transactions && balanceData.transactions.length > 0 ? (
                <Card className="py-3 px-5">
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th>日付</th>
                                <th>タイプ</th>
                                <th>商品ID</th>
                                <th>説明</th>
                                <th>入出金</th>
                            </tr>
                        </thead>
                        <tbody>
                            {balanceData.transactions.map(transaction => (
                                <tr key={transaction.id}>
                                    <td>{new Date(transaction.date).toLocaleDateString()}</td>
                                    <td>{transaction.type}</td>
                                    <td>{transaction.productId}</td>
                                    <td>{transaction.description}</td>
                                    <td>¥{transaction.shopRevenue}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card>
            ) : (
                <Alert variant="warning">履歴が見つかりません。</Alert>
            )}

            {balanceData && balanceData.balance && (<>
                <BankAccountModal
                    show={showBankAccountModal}
                    handleClose={handleBankAccountModalClose}
                    initialBankAccount={balanceData.balance.bankAccount}
                />
                <CreateWithdrawalModal
                    show={showWithdrawalModal}
                    handleClose={handleWithdrawalModalClose}
                    onWithdraw={handleWithdraw}
                    currentBalance={balanceData.balance.balance}
                />
            </>)}

        </Container>
    );
};

export default BalanceScreen;