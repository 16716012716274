import React from 'react';
import { formatDateRange } from '../utils/dateUtils';

const OrderProduct = React.memo(({ product }) => {
    return (
        <div className='product rounded'>
            <div className='product-img'>
                <img src={product.image} alt={product.name} loading='lazy' />
            </div>

            <div className='product-body'>
                <b className='product-title'>{product.name}</b>
                <small>
                    {product.rentalPeriod ? 'レンタル' : '購入'}
                </small>

                <div className='product-price-wrapper'>
                    <div className='product-price'>
                        {product.startDate && product.endDate ? (
                            <div>{formatDateRange(product.startDate, product.endDate)}</div>
                        ) : (product.useSubscription ? (
                            <div>サブスク利用</div>
                        ) : null)}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default OrderProduct;
