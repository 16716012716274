import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, ListGroup, Image, Form, Button, Card, Badge, Container } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import Message from '../components/Message';
import { removeFromCart, saveDeliveryOption } from '../slices/cartSlice';
import Meta from '../components/Meta';

const CartScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const cart = useSelector((state) => state.cart);
    const { cartItems } = cart;

    const removeFromCartHandler = async (id) => {
        dispatch(removeFromCart(id));
    };

    const { userInfo } = useSelector((state) => state.auth);

    // サブスクリプション利用状況の確認関数
    const checkSubscriptionLimit = () => {
        if (!userInfo || !userInfo.subscription) {
        return true; // サブスクリプションがない場合は制限なし
        }
    
        // カート内のサブスク利用対象商品の集計
        const cartSubscriptionItems = cartItems.filter(item => item.useSubscription);
    
        // サブスクリプションの利用可能量を超えているかの確認
        const isOverLimit = userInfo.subscription.usedItems + cartSubscriptionItems.length > userInfo.subscription.maxItems;
    
        return !isOverLimit;
    };

    const checkoutHandler = () => {
        if (checkSubscriptionLimit()) {
          navigate('/login?redirect=/shipping');
        } else {
          // ここでユーザーに警告を表示する（例: モーダル、トースト通知など）
          alert('サブスクリプションの利用可能回数を超えています。');
        }
    };

    return (
        <Container>
            <Meta title='ふるぷら | Cart'></Meta>
            <Row className='mt-4'>
                <Col md={8}>
                    <h1 style={{ marginBottom: '20px' }}>Shopping Cart</h1>
                    { cartItems.length === 0 
                        ? (
                            <Message>
                                カートは空です <Link to='/'>Go Back</Link>
                            </Message>
                        )
                        : (
                            <ListGroup variant='flush'>
                                { cartItems.map((item) => (
                                    <ListGroup.Item key={item.id}>
                                        <Row>
                                            <Col md={2}>
                                                <Image src={ item.image } alt={item.name} fluid rounded />
                                            </Col>
                                            <Col md={3}>
                                                <Link to={`/product/${item.id}`}>{item.name}</Link>
                                            </Col>
                                            <Col md={2}>
                                                {item.serviceOption === 'rent' ? (
                                                    <>
                                                        <small className='small-text'>レンタル: </small>
                                                        ¥{item.finalPrice}
                                                        <br />
                                                        <small className='small-text'>
                                                            期間: {item.rentalPeriod === '4days' ? '3泊4日' : item.rentalPeriod === '2weeks' ? '2週間' : item.rentalPeriod === '1month' ? '1ヶ月' : '無期限'}
                                                        </small> {/* レンタル期間の追加 */}
                                                    </>
                                                ) : item.serviceOption === 'buy' ? (
                                                    <>
                                                        <small className='small-text'>購入: </small>
                                                        ¥{item.finalPrice} 
                                                    </>
                                                ) : '未選択'} 
                                                {item.useSubscription && 
                                                    <>
                                                        <br />
                                                        <small className='small-text'>【サブスク利用】</small>
                                                    </>
                                                }
                                                <br />
                                                (<small className='small-text'><span className='smaller-text'>商品価格:</span> ¥{item.price}</small>)
                                            </Col>

                                            <Col md={2}>
                                                {
                                                    item.inStore ? (
                                                        <div>店舗<small className='small-text'>で受け取る</small></div>
                                                    ) : (
                                                        <div>配送</div>
                                                    )
                                                }
                                            </Col>
                                            <Col md={2}>
                                                {item.status === 'available' ? (
                                                    <Badge bg="success p-2">在庫あり</Badge>
                                                ) : item.status === 'rented' ? (
                                                    <Badge bg="danger p-2">Rented</Badge>
                                                ) : (
                                                    <Badge bg="danger p-2">品切れ中</Badge>
                                                )}
                                            </Col>
                                            <Col md={1}>
                                                <Button 
                                                    type='button' 
                                                    variant='light' 
                                                    onClick={ () => removeFromCartHandler(item.id) }
                                                >
                                                    <FaTrash />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )
                    }
                </Col>
                <Col md={4}>
                    <Card>
                        <ListGroup variant='flush'>
                            <ListGroup.Item>
                                <h2>
                                    小計 { cartItems.length } 点
                                </h2>
                                { cartItems
                                    .reduce((acc, item) => acc + item.finalPrice, 0)
                                    .toFixed(0)
                                }円 (税込み)
                            </ListGroup.Item>
                            
                            <ListGroup.Item>
                                <Button 
                                    type='button' 
                                    className='btn-block' 
                                    disabled={cartItems.length === 0 || !checkSubscriptionLimit()}
                                    onClick={ checkoutHandler }
                                >
                                    Continue
                                </Button>
                                {!checkSubscriptionLimit() && 
                                    <Message variant='danger mt-3'>
                                        サブスクリプションの無料枠は、あと {userInfo.subscription.maxItems - userInfo.subscription.usedItems} 点です。
                                        {userInfo && userInfo.subscription ? (
                                            <small>
                                                <strong>プラン:</strong> {userInfo.subscription.planName}
                                                <br />
                                                <strong>現在レンタルしている数::</strong> {userInfo.subscription.usedItems}/{userInfo.subscription.maxItems}
                                                <br />
                                                <strong>商品価格上限:</strong> ¥{userInfo.subscription.itemPriceLimit}
                                            </small>
                                        ) : null}
                                    </Message>
                                }
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default CartScreen;