import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Row, Col, ListGroup, Image, Card, Badge, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from '../components/StripeCheckoutForm';
import Message from '../components/Message';
import Loader from '../components/Loader';
import ShopInfoModal from '../components/ShopInfoModal';
import { 
    useGetOrderDetailsQuery,
} from '../slices/ordersApiSlice';
import {
    useStripePaymentMutation
} from '../slices/stripeApiSlice';
import { formatDate ,calculateDaysLeft } from '../utils/dateUtils';
import { FaStore } from 'react-icons/fa';
import { STRIPE_PUBLISHABLE_KEY } from '../constants/constants';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);


const OrderScreen = () => {
    const { id: orderId } = useParams();
    const navigate = useNavigate();

    const { data: order, refetch, isLoading, error } = useGetOrderDetailsQuery(orderId);

    const [
        stripePayment,
        {isLoading: loadingStripe, error: errorStripe}
    ] = useStripePaymentMutation(order);

    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        if (!errorStripe && !loadingStripe && stripePayment && order) {
            const fetchClientSecret = async () => {
                try {                                                         // stripePayment は非同期関数として扱われ、Promise を返します。
                    const response = await stripePayment(order).unwrap();       // unwrap() メソッドは、フェッチされたデータまたはエラーを直接返します。
                    setClientSecret(response.clientSecret);
                } catch (err) {
                    toast.error(err?.data?.message || err.message);
                }
            };
            if (order && !order.isPaid) {
                fetchClientSecret();
            }
        }
    }, [stripePayment, order]);    // 依存関係に stripePayment と order を追加

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
        locale: 'ja', // Stripe UIを日本語に設定
    };

    const [showModal, setShowModal] = useState(false);
    const [modalInfo, setModalInfo] = useState({ shopId: null, startDate: null, endDate: null, daysLeft: null });

    const handleShowModal = (shopId, startDate, endDate, daysLeft) => {
        setModalInfo({ shopId, startDate, endDate, daysLeft });
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    const handlePaymentSuccess = () => {
        navigate('/');
    }

    return (
        isLoading 
            ? <Loader /> 
            : error ? (
                <Message variant="danger">{ error?.data?.message || error.error }</Message>
            )
            : (
                <Container>
                    <h1>ご注文受付ID: {order.id}</h1>
                    <Row>
                        <Col md={8}>
                            <ListGroup variant='flush'>
                                <ListGroup.Item>
                                    <h2>お届け先</h2>
                                    <p>
                                        <strong>お名前: </strong> {order.userName}
                                    </p>
                                    {/* <p>
                                        <strong>Email: </strong> {order.user.email}
                                    </p> */}
                                    <p>
                                        <strong>住所: </strong>
                                        {'〒'}{order.postalCode} {order.prefecture} {order.city} {order.streetAddress} {order.buildingName}   
                                    </p>
                                    { order.isDelivered ? (
                                        <Badge bg="success p-2">
                                            {order.deliveredAt} に発送済み
                                        </Badge>
                                    ) : (
                                        <Badge variant="danger">未発送</Badge>
                                    )}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <h2>お支払い方法</h2>
                                    <p>
                                        <strong>お支払い方法: </strong>
                                        {order.paymentMethod}
                                    </p>
                                    { order.isPaid ? (
                                        <Message variant='success'>
                                            {formatDate(order.paidAt)} にお支払い済み
                                        </Message>
                                    ) : (
                                        <Message variant='danger'>
                                            お支払いが済んでおりません。
                                            ご指定の手順でお支払いをお願いいたします。
                                        </Message>
                                    )}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <h2>商品</h2>
                                    { order.RentalItems.map((item, index) => (
                                        <ListGroup.Item key={index}>
                                            <Row>
                                                <Col md={1}>
                                                    <Image src={item.image} alt={item.name} fluid rounded />
                                                </Col>
                                                <Col>
                                                    <Link to={`/product/${item.product}`}>
                                                        {item.name}
                                                    </Link>
                                                </Col>
                                                <Col md={4}>
                                                    レンタル： ¥{ item.finalPrice }
                                                    <br />
                                                    <small className='small-text'>
                                                        {item.rentalPeriod === '4days' ? '3泊4日' : item.rentalPeriod === '2weeks' ? '2週間' : item.rentalPeriod === '1month' ? '1ヶ月' : '無期限'}
                                                    </small>
                                                </Col>
                                                <Col md={2}>
                                                    {item.inStore ? (
                                                        <div 
                                                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#007bff', textDecoration: 'underline', fontWeight: 'bold' }} 
                                                            onClick={() => {
                                                                const daysLeft = item.status === 'rented' && item.rentalPeriod !== 'unlimited'
                                                                    ? calculateDaysLeft(item.startDate, item.endDate)
                                                                    : null;
                                                                handleShowModal(item.shop, item.startDate, item.endDate, daysLeft);
                                                            }}
                                                        >
                                                            <FaStore style={{ marginRight: '5px' }} />
                                                            お店<small className='small-text'>で受け取る</small>
                                                        </div>
                                                    ) : (
                                                        <div>配送</div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    ))}
                                    { order.PurchaseItems.map((item, index) => (
                                        <ListGroup.Item key={index}>
                                            <Row>
                                                <Col md={1}>
                                                    <Image src={item.image} alt={item.name} fluid rounded />
                                                </Col>
                                                <Col>
                                                    <Link to={`/product/${item.product}`}>
                                                        {item.name}
                                                    </Link>
                                                </Col>
                                                <Col md={4}>
                                                    購入： ¥{ item.finalPrice }
                                                </Col>
                                                <Col md={2}>
                                                    {item.inStore ? (
                                                        <div 
                                                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'blue' }} 
                                                            onClick={() => handleShowModal(item.shop)}
                                                        >
                                                            <FaStore style={{ marginRight: '5px' }} />
                                                            お店<small className='small-text'>で受け取る</small>
                                                        </div>
                                                    ) : (
                                                        <div>配送</div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>   
                                    ))}
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <ListGroup variant='flush'>
                                    <ListGroup.Item>
                                        <h2>ご注文内容（税込み）</h2>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>商品:</Col>
                                            <Col>
                                                {order.itemsPrice}円
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>送料:</Col>
                                            <Col>
                                                {order.shippingPrice}円
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>合計:</Col>
                                            <Col>
                                                {order.totalPrice}円
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    { !order.isPaid && (
                                            <ListGroup.Item>
                                                {loadingStripe && <Loader />}
                                                {!clientSecret ? (
                                                    <Message variant="danger">
                                                        支払い情報を取得中にエラーが発生しました。
                                                    </Message>
                                                ) : (
                                                    <Elements options={options} stripe={stripePromise}>
                                                        <StripeCheckoutForm onSuccess={handlePaymentSuccess} />
                                                    </Elements>
                                                )}
                                            </ListGroup.Item>
                                        )}
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                    <ShopInfoModal
                        show={showModal}
                        shopId={modalInfo.shopId}
                        startDate={modalInfo.startDate}
                        endDate={modalInfo.endDate}
                        daysLeft={modalInfo.daysLeft}
                        onHide={handleCloseModal}
                    />
                </Container>
            )
    )
}

export default OrderScreen;