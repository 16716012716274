import { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form, Button, Row, Col, Image, Card, ListGroup, Container } from 'react-bootstrap';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import ImageGallery from '../../components/ImageGallery';
import TagManagement from '../../components/TagManagement.jsx';
import { toast } from 'react-toastify';
import { 
    useUpdateProductMutation, 
    useGetProductDetailsQuery,
    useUploadProductImageMutation,
    useUploadSubImagesMutation,
    useDeleteProductImageMutation,
} from '../../slices/productsApiSlice';
import { GENDER, SIZE, COLOR, MEASUREMENTS, MEASUREMENTS_MAPPING } from '../../constants/productConstants';
import { FaTrash, FaEdit, FaUsb, FaBluetoothB } from 'react-icons/fa';
import {
    getStoredSerialPortIdentifier, 
    getStoredBluetoothDeviceName,
    requestAndStoreSerialPortIdentifier,
    requestAndStoreBluetoothDeviceName,
    chooseAndConnectToBLEDevice,
    connectToBLEDevice, 
    setImage, 
    printImage, 
    disconnectBLEDevice 
} from '../../utils/phomemoPrinter.ts';
import { useGetCategoryTreeQuery } from '../../slices/categoriesApiSlice.js';
import { useGetBrandsQuery } from '../../slices/brandsApiSlice.js';

const ProductEditScreen = () => {
    const STATUS_OPTIONS = {
        available: '在庫あり',
        sold: '売り切れ',
        rented: 'レンタル中',
    };

    const [isEditing, setIsEditing] = useState(false);

    const { id: productId } = useParams();

    const { data: product, isLoading, refetch, error } = useGetProductDetailsQuery(productId);
    const { data: categoryTree, isLoading: isCategoryLoading, error: categoryError } = useGetCategoryTreeQuery();
    const { data: brands, isLoading: isBrandsLoading, error: brandsError } = useGetBrandsQuery();


    const [updateProduct, { isLoading: loadingUpdate }] = useUpdateProductMutation();
    const [uploadProductImage, { isLoading: loadingUpload }] = useUploadProductImageMutation();
    const [UploadSubImages, { isLoading: loadingSubImages } ] = useUploadSubImagesMutation();
    const [deleteProductImage, { isLoading: loadingDeleteImage }] = useDeleteProductImageMutation();

    const [productData, setProductData] = useState({
        name: '',
        price: 0,
        image: '',
        subImages: [],
        brandId: '',
        categoryId: '', // 初期値を空文字列に設定
        status: 'available',
        description: '',
        gender: 1,
        sizeLabel: [],
        sizeMeasurements: {},
        color: [],
        condition: '',
        material: '',
    });

    const [parentCategories, setParentCategories] = useState([]);
    const [childCategories, setChildCategories] = useState([]);
    const [selectedParentCategory, setSelectedParentCategory] = useState('');
    const [selectedChildCategory, setSelectedChildCategory] = useState('');

    const [serialPortIdentifier, setSerialPortIdentifier] = useState('');
    const [bluetoothDeviceName, setBluetoothDeviceName] = useState('');

    const descriptionRef = useRef(null);
    const conditionRef = useRef(null);
    const materialRef = useRef(null);

    useEffect(() => {
        if (product) {
            setProductData({
                name: product.name,
                price: product.price,
                image: product.image,
                subImages: product.subImages || [],
                brandId: product.brand?.id,
                categoryId: product.category.id || '',
                status: product.status,
                description: product.description,
                gender: product.gender,
                sizeLabel: product.size?.label || [],
                sizeMeasurements: product.size?.measurements || {},
                color: product.color || [],
                condition: product.condition || '',
                material: product.material || '',
            });

            if (product.categoryId) {
                const parentCategory = categoryTree?.find(cat => cat.id === product.category.parentId);
                if (parentCategory) {
                    setSelectedParentCategory(parentCategory.id);
                    const children = parentCategory.childrenCategories || [];
                    setChildCategories(children);
                    setSelectedChildCategory(product.category.id);
                } else {
                    // 親カテゴリがない場合（トップレベルカテゴリ）
                    setSelectedParentCategory('');
                    setChildCategories([]);
                    setSelectedChildCategory(product.category.id);
                }
            }
        }
    }, [product, categoryTree]);

    useEffect(() => {
        const storedSerialPortIdentifier = getStoredSerialPortIdentifier();
        if (storedSerialPortIdentifier) {
            setSerialPortIdentifier(storedSerialPortIdentifier);
        }
        const storedBluetoothDeviceName = getStoredBluetoothDeviceName();
        if (storedBluetoothDeviceName) {
            setBluetoothDeviceName(storedBluetoothDeviceName);
        }
    }, []);

    // カテゴリーデータの取得と親カテゴリの設定
    useEffect(() => {
        if (categoryTree) {
            setParentCategories(categoryTree);
        }
    }, [categoryTree]);

    const resizeTextArea = (e) => {
        const textarea = e.target;
        textarea.style.height = 'auto';  // 一旦高さをリセット
        textarea.style.height = `${textarea.scrollHeight}px`;  // スクロールの高さに基づいて再設定
    };

    const resizeAllTextAreas = () => {
        [descriptionRef, conditionRef, materialRef].forEach(ref => {
            if (ref.current) {
                const textarea = ref.current;
                textarea.style.height = 'auto';
                textarea.style.height = `${textarea.scrollHeight}px`;
            }
        });
    };

    useEffect(() => {
        if (isEditing) {
            resizeAllTextAreas();
        }
    }, [isEditing]);
    

    const handleChange = useCallback((e) => {
        const { name, value, checked, type } = e.target;
        setProductData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? (checked ? [...prev[name], Number(value)] : prev[name].filter(v => v !== Number(value))) : value,
        }));
        if (type === 'textarea') {
            resizeTextArea(e);
        }
    }, []);

    const handleChangeMeasurement = useCallback((e, measurementName) => {
        const { value } = e.target;
        setProductData(prev => ({
            ...prev,
            sizeMeasurements: {
                ...prev.sizeMeasurements,
                [measurementName]: Number(value)
            }
        }));
    }, []);    
    

    const handleCancel = useCallback(() => {
        setIsEditing(false);
        if (product) {
            setProductData(prev => ({
                ...prev,
                name: product.name,
                price: product.price,
                brandId: product.brand?.id,
                categoryId: product.category.id,
                status: product.status,
                description: product.description,
                gender: product.gender,
                sizeLabel: product.size?.label || [],
                sizeMeasurements: product.size?.measurements || {},
                color: product.color || [],
                condition: product.condition || '',
                material: product.material || '',
            }));
            // 親カテゴリと子カテゴリの設定をリセット
            if (product.categoryId) {
                const parentCategory = categoryTree?.find(cat => cat.id === product.category.parentId);
                if (parentCategory) {
                    setSelectedParentCategory(parentCategory.id);
                    const children = parentCategory.childrenCategories || [];
                    setChildCategories(children);
                    setSelectedChildCategory(product.category.id);
                } else {
                    setSelectedParentCategory('');
                    setChildCategories([]);
                    setSelectedChildCategory(product.category.id);
                }
            }
        }
    }, [product]);

    const submitHandler = async (e) => {
        e.preventDefault();
        const { name, price, image, categoryId, status, gender } = productData;
        if (!name.trim()) {
            toast.error('商品名は必須です');
            return;
        }
        if (price <= 0) {
            toast.error('価格は0より大きい値を入力してください');
            return;
        }
        if (!image) {
            toast.error('メイン画像をアップロードしてください');
            return;
        }
        if (!categoryId || isNaN(categoryId)) {
            toast.error('カテゴリを選択してください');
            return;
        }
        if (!status) {
            toast.error('在庫状況を選択してください');
            return;
        }
        if (!gender) {
            toast.error('性別を選択してください');
            return;
        }
        try {
            const updatedProduct = await updateProduct({
                productId,
                ...productData,
            }).unwrap();
    
            toast.success('商品情報が更新されました');
    
            // `refetch` を削除し、`product` を更新
            setProductData({
                ...productData,
                ...updatedProduct,
            });
    
            setIsEditing(false);
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };    
    
    const uploadFileHandler = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        formData.append('shop', product.shop);
        formData.append('previousImage', productData.image);
        try {
            const res = await uploadProductImage({ productId ,formData}).unwrap();
            toast.success(res.message);
            setProductData(prevProductData => ({ ...prevProductData, image: res.image }));
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        if (files.length > 5) {
            alert('一度にアップロードできる画像は5枚までです。');
            event.target.value = ''; // 入力をリセット
        } else if (files.length + productData.subImages.length > 5) {
            alert('合計で5枚を超える画像はアップロードできません。');
            event.target.value = ''; // 入力をリセット
        } else {
            uploadSubImagesHandler(files);
        }
    };
    const uploadSubImagesHandler = async (files) => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('images', files[i]);
        }
        try {
            const res = await UploadSubImages({ productId, formData }).unwrap();
            toast.success('Sub images uploaded successfully');
            setProductData(prevProductData => ({ ...prevProductData, subImages: res.subImages }));
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    }
    
    const deleteImageHandler = async (previousImage) => {
        try {
            if (window.confirm('画像を削除しますか？')) {
                await deleteProductImage({ productId, previousImage }).unwrap();
                toast.success('画像が削除されました');
                setProductData(prevProductData => ({
                    ...prevProductData,
                    subImages: prevProductData.subImages.filter(img => img !== previousImage)
                }));
            }
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    }

    const handleChangeSerialPort = async () => {
        try {
            const newPortIdentifier = await requestAndStoreSerialPortIdentifier();
            setSerialPortIdentifier(newPortIdentifier);
            toast.success('USB登録プリンターが更新されました');
        } catch (error) {
            console.error('Error changing serial port:', error);
            toast.error('USB登録プリンターの更新に失敗しました');
        }
    };
    
    const handleChangeBluetoothDevice = async () => {
        try {
            const newDeviceName = await requestAndStoreBluetoothDeviceName();
            setBluetoothDeviceName(newDeviceName);
            toast.success('Bluetooth登録プリンターが更新されました');
        } catch (error) {
            console.error('Error changing Bluetooth device:', error);
            toast.error('Bluetooth登録プリンターの更新に失敗しました');
        }
    };

    const handleDeleteSerialPort = async () => {
        try {
            setSerialPortIdentifier('');
            localStorage.removeItem('serialPortIdentifier');
            toast.success('USB登録プリンターが削除されました');
        } catch (error) {
            console.error('Error deleting serial port:', error);
            toast.error('USB登録プリンターの削除に失敗しました');
        }
    };

    const handleDeleteBluetoothDevice = async () => {
        try {
            setBluetoothDeviceName('');
            localStorage.removeItem('bluetoothDeviceName');
            toast.success('Bluetooth登録プリンターが削除されました');
        } catch (error) {
            console.error('Error deleting Bluetooth device:', error);
            toast.error('Bluetooth登録プリンターの削除に失敗しました');
        }
    };

    async function ensureBluetoothConnection() {
        if (!bluetoothDeviceName) {
            const userConfirmed = window.confirm('Bluetooth登録プリンターが未設定です。新しいプリンターを選択しますか？');
            if (!userConfirmed) return false;
    
            const newDeviceName = await chooseAndConnectToBLEDevice();
            if (!newDeviceName) return false;
    
            setBluetoothDeviceName(newDeviceName);
            localStorage.setItem('bluetoothDeviceName', newDeviceName);
        } else {
            const connected = await connectToBLEDevice(bluetoothDeviceName);
            if (!connected) return false;
        }
        return true;
    }
    
    async function handlePrintQRCode() {
        try {
            const connected = await ensureBluetoothConnection();
            if (!connected) {
                toast.error('Bluetooth登録プリンターへの接続に失敗しました');
                return;
            }
    
            const imageSet = await setImage(`https://www.furupura.jp/product/${productId}`);
            if (!imageSet) {
                toast.error('QRコードの設定に失敗しました');
                return;
            }
    
            const printed = await printImage('bluetooth');
            if (!printed) {
                toast.error('印刷に失敗しました');
            } else {
                toast.success('QRコードを印刷しました');
            }
    
        } catch (error) {
            console.error('Error printing QR code:', error);
            toast.error('QRコードの印刷に失敗しました');
        }
    }    
    
    // 親カテゴリが変更されたときに子カテゴリを更新するハンドラー
    const handleParentCategoryChange = (e) => {
        const parentId = e.target.value;
        setSelectedParentCategory(parentId);
        setProductData(prev => ({
            ...prev,
            categoryId: '', // 子カテゴリが選択されていない状態にリセット
        }));
        setSelectedChildCategory('');

        if (parentId === '') {
            // 親カテゴリが未選択の場合、子カテゴリもリセット
            setChildCategories([]);
        } else {
            const parent = parentCategories.find(cat => String(cat.id) === String(parentId));
            if (parent && parent.childrenCategories) {
                setChildCategories(parent.childrenCategories);
            } else {
                setChildCategories([]);
            }
        }
    };

    // 子カテゴリが変更されたときにproductData.categoryを更新
    const handleChildCategoryChange = (e) => {
        const childId = e.target.value;
        setSelectedChildCategory(childId);
        setProductData(prev => ({
            ...prev,
            categoryId: childId,
        }));
    };

    return (
        <Container>
            <Link to='/shop/productlist' className='btn btn-light my-3'>
                戻る
            </Link>
            <h1>商品管理画面</h1>
            {isLoading ? (<h2>Loading...</h2>)
            : error ? (<Message variant='danger'> { error?.data?.message || error.error } </Message>) 
            : (
                <Row>
                    <Col md={5}>
                        {loadingUpload && <Loader />}

                        {isEditing ? (<>
                            <Row className='my-4 justify-content-center'>
                                <Col md={10} className='d-flex justify-content-center'>
                                    <div className='image-container'>
                                        <Image src={productData.image} alt={productData.name} fluid />
                                    </div>
                                </Col>
                            </Row>

                            <Row className='px-5 mb-5'>
                                <Form.Group controlId='image' className=''>
                                    <Form.Label>メイン画像をアップロードする</Form.Label>
                                    <Form.Control
                                        type='file'
                                        label='写真を選択する'
                                        onChange={ uploadFileHandler }
                                    ></Form.Control>
                                </Form.Group>
                            </Row>

                            <Row className='my-2 justify-content-center'>
                                <Col md={10} className='image-grid'>
                                    {productData.subImages?.map((subImage, index) => (
                                        <div className='d-flex flex-column align-items-center'>
                                            <div key={index} className="thumbnail-container">
                                                <Image 
                                                    src={subImage} 
                                                    alt={`Subimage ${index}`}
                                                    fluid
                                                />
                                            </div>
                                            <Button 
                                                variant='danger' 
                                                className='btn-sm mt-2'
                                                onClick={() => deleteImageHandler(subImage)}
                                            >
                                                <FaTrash color='white' />
                                            </Button>
                                        </div>
                                    ))}
                                </Col>
                            </Row>

                            <Row className='my-2 px-5'>
                                <Form.Group controlId='subImages' className=''>
                                    <Form.Label>サブ写真をアップロードする</Form.Label>
                                    <Form.Control
                                        type='file'
                                        label='Choose File'
                                        onChange={handleFileChange}
                                        multiple
                                    ></Form.Control>
                                </Form.Group>
                            </Row>
                        </>) : (
                            <ImageGallery images={[productData.image, ...productData.subImages]} />
                        )}
                    </Col>
                    <Col md={4} className='px-3'>
                        {loadingUpdate && <Loader />}
                        {isEditing ? (
                            <Form variant='flush'>
                                <Form.Group controlId='name' className='my-2'>
                                    <Form.Label>商品名</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={productData.name}
                                        onChange={handleChange} // handleChangeを使用
                                    />
                                </Form.Group>

                                <Form.Group controlId="parentCategory" className='my-2'>
                                    <Form.Label>親カテゴリ</Form.Label>
                                    <Form.Select
                                        name="parentCategory"
                                        value={selectedParentCategory}
                                        onChange={handleParentCategoryChange}
                                    >
                                        <option value="">選択してください</option>
                                        {parentCategories.map(parent => (
                                            <option key={parent.id} value={parent.id}>{parent.name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="category" className='my-2'>
                                    <Form.Label>カテゴリ</Form.Label>
                                    <Form.Select
                                        name="category"
                                        value={selectedChildCategory}
                                        onChange={handleChildCategoryChange}
                                        required
                                    >
                                        <option value="">選択してください</option>
                                        {childCategories.map(child => (
                                            <option key={child.id} value={child.id}>{child.name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId='description' className='my-2'>
                                    <Form.Label>商品説明</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        name='description'
                                        placeholder='Enter description' 
                                        value={productData.description}
                                        onChange={handleChange}
                                        ref={descriptionRef}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId="gender">
                                    <Form.Label>性別</Form.Label>
                                    <Form.Select
                                        name="gender"
                                        value={productData.gender}
                                        onChange={handleChange}
                                    >
                                    <option value="">選択してください</option>
                                    {Object.entries(GENDER).map(([key, value]) => (
                                        <option key={key} value={key}>{value}</option>
                                    ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId='brand' className='my-2'>
                                    <Form.Label>ブランド</Form.Label>
                                    {isBrandsLoading ? (
                                        <Loader />
                                    ) : brandsError ? (
                                        <Message variant='danger'>ブランドデータの取得に失敗しました</Message>
                                    ) : (
                                        <Form.Select
                                            name="brandId"
                                            value={productData.brandId}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">選択してください</option>
                                            {brands && brands.map(brand => (
                                                <option key={brand.id} value={brand.id}>{brand.name}</option>
                                            ))}
                                        </Form.Select>
                                    )}
                                </Form.Group>

                                <Form.Group className='my-2'>
                                    <Form.Label>サイズ</Form.Label>
                                    {Object.entries(SIZE).map(([key, value]) => (
                                        <Form.Check
                                            key={key}
                                            type="checkbox"
                                            label={value}
                                            name="sizeLabel"
                                            value={key}
                                            checked={productData.sizeLabel.includes(Number(key))}
                                            onChange={(e) => handleChange({ 
                                                target: { 
                                                    name: e.target.name, 
                                                    value: e.target.value, 
                                                    checked: e.target.checked, 
                                                    type: 'checkbox' 
                                                }})
                                            }
                                        />
                                    ))}
                                </Form.Group>

                                {productData.parentCategory && MEASUREMENTS[productData.parentCategory] &&
                                    MEASUREMENTS[productData.parentCategory].measurements.map(measurement => (
                                        <Form.Group key={measurement} controlId={`sizeMeasurement-${measurement}`} className='my-2'>
                                            <Form.Label>{MEASUREMENTS_MAPPING[measurement]}</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={productData.sizeMeasurements[measurement] || ''}
                                                onChange={(e) => handleChangeMeasurement(e, measurement)} // 新しいhandleChangeMeasurement関数を使用
                                            />
                                        </Form.Group>
                                    ))
                                }


                                <Form.Group className='my-2'>
                                    <Form.Label>色</Form.Label>
                                    {Object.entries(COLOR).map(([key, value]) => (
                                        <Form.Check
                                            key={key}
                                            type="checkbox"
                                            label={value}
                                            name="color"
                                            value={key}
                                            checked={productData.color.includes(Number(key))}
                                            onChange={(e) => handleChange({ 
                                            target: { 
                                                name: e.target.name, 
                                                value: e.target.value, 
                                                checked: e.target.checked, 
                                                type: 'checkbox' 
                                            }})}
                                        />
                                    ))}
                                </Form.Group>


                                <Form.Group controlId='condition' className='my-2'>
                                    <Form.Label>状態</Form.Label>
                                    <Form.Control 
                                        as='textarea'
                                        name='condition'
                                        placeholder='Enter condition' 
                                        value={productData.condition}
                                        onChange={handleChange}
                                        ref={conditionRef}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='material' className='my-2'>
                                    <Form.Label>素材</Form.Label>
                                    <Form.Control 
                                        as='textarea'
                                        name='material'
                                        placeholder='Enter material' 
                                        value={productData.material}
                                        onChange={handleChange}
                                        ref={materialRef}
                                    ></Form.Control>
                                </Form.Group>
                            </Form>
                        ) : (
                            <ListGroup variant='flush'>
                                    <ListGroup.Item>
                                        <h3>{product.name}</h3>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row className='px-2'>
                                            <Col md={3}> <strong>商品ID:</strong> </Col>
                                            <Col className='text-end'> {product.id} </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row className='px-2'>
                                            <Col md={3}> <strong>カテゴリ:</strong> </Col>
                                            <Col className='text-end'> 
                                                {product.parentCategory 
                                                    ? `${product.parentCategory.name} ＞ ${product.category.name}` 
                                                    : product.category.name 
                                                }
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row className='px-2'>
                                            <Col md={3}> <strong>商品説明:</strong> </Col>
                                            <Col className='text-end'> {product.description} </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row className='px-2'>
                                            <Col md={3}> <strong>性別:</strong> </Col>
                                            <Col className='text-end'> {GENDER[product.gender]} </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row className='px-2'>
                                            <Col md={3}> <strong>ブランド:</strong> </Col>
                                            <Col className='text-end'> {product.brand?.name} </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row className='px-2'>
                                            <Col md={3}> <strong>サイズ:</strong> </Col>
                                            <Col className='text-end'> {product.size?.label.map(sizeLabel => SIZE[sizeLabel]).join(', ')} </Col>
                                        </Row>
                                        {product.size && product.size.measurements && Object.entries(product.size.measurements).map(([key, value]) => (
                                            <Row className='px-2'>
                                                <Col md={4}> <strong>{MEASUREMENTS_MAPPING[key]}:</strong> </Col>
                                                <Col className='text-end'> {value} </Col>
                                            </Row>
                                        ))}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row className='px-2'>
                                            <Col md={3}> <strong>色:</strong> </Col>
                                            <Col className='text-end'> {product.color.map(color => COLOR[color]).join(', ')} </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row className='px-2'>
                                            <Col md={3}> <strong>状態:</strong> </Col>
                                            <Col className='text-end'> {product.condition} </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row className='px-2'>
                                            <Col md={3}> <strong>素材:</strong> </Col>
                                            <Col className='text-end'> {product.material} </Col>
                                        </Row>
                                    </ListGroup.Item>
                            </ListGroup>
                        )}
                    </Col>
                    <Col md={3}>
                        <Card>
                            <ListGroup variant='flush'>
                                <ListGroup.Item>
                                    <Row>
                                        <Col md={5}>価格(税込):</Col>
                                        <Col className='text-end'>
                                            {isEditing ? (
                                                <Form.Control 
                                                    className='text-end'
                                                    type='number' 
                                                    name='price'
                                                    placeholder='Enter price' 
                                                    value={productData.price}
                                                    onChange={handleChange}
                                                />
                                            ) : (
                                                <strong className='mx-2'>{product.price}円</strong>
                                            )}
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Row>
                                        <Col md={5}>在庫状況:</Col>
                                        <Col className='text-end'>
                                            {isEditing ? (
                                                <Form.Select
                                                className='text-end'
                                                    name="status"
                                                    value={productData.status}
                                                    onChange={handleChange} // この関数が正しく productData の status を更新しているか確認
                                                >
                                                    {Object.entries(STATUS_OPTIONS).map(([key, label]) => (
                                                        <option key={key} value={key}>{label}</option>
                                                    ))}
                                                </Form.Select>
                                            ) : (
                                                <strong className='mx-2'>{STATUS_OPTIONS[product.status]}</strong>
                                            )}
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <TagManagement 
                                        productId={productId} 
                                        existingTags={product.Tags} 
                                        refetch={refetch}
                                        isEditing={isEditing} // isEditing を追加
                                    />
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    {isEditing ? (
                                        <Row>
                                            <Col>
                                                <Button 
                                                    variant="outline-success"
                                                    className='btn-block' 
                                                    type='button'
                                                    onClick={submitHandler}
                                                >
                                                    更新する
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    variant="outline-secondary"
                                                    className='btn-block' 
                                                    type='button'
                                                    onClick={handleCancel}
                                                >
                                                    キャンセル
                                                </Button>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Button 
                                            className='btn-block' 
                                            type='button'
                                            onClick={() => setIsEditing(true)}
                                        >
                                            編集する
                                        </Button>
                                    )}
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                        <Card className='mt-3'>
                            <ListGroup variant='flush'>
                                <ListGroup.Item>
                                    <Row className='mb-2'>
                                        <Col><strong>登録プリンター</strong></Col>
                                    </Row>
                                    <Row className='mb-2'>
                                        <Col>
                                            <FaUsb className='mb-1'/> <strong>USB：</strong> <br /> 
                                            {serialPortIdentifier || '未設定'}
                                        </Col>
                                        <Col className='d-flex align-items-center'>
                                            <Button className='btn-sm me-1' variant="outline-secondary" onClick={handleChangeSerialPort}>
                                                <FaEdit />
                                            </Button>
                                            <Button className='btn-sm' variant="outline-danger" onClick={handleDeleteSerialPort}>
                                                <FaTrash />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FaBluetoothB className='mb-1'/> <strong>Bluetooth：</strong> <br />
                                            {bluetoothDeviceName || '未設定'}
                                        </Col>
                                        <Col className='d-flex align-items-center'>
                                            <Button className='btn-sm me-1' variant="outline-secondary" onClick={handleChangeBluetoothDevice}>
                                                <FaEdit />
                                            </Button>
                                            <Button className='btn-sm' variant="outline-danger" onClick={handleDeleteBluetoothDevice}>
                                                <FaTrash />
                                            </Button>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Button onClick={handlePrintQRCode} variant='outline-secondary'>
                                        QRコードを印刷
                                    </Button>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
            )}
        </Container>
    )
}

export default ProductEditScreen;