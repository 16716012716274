import { useMemo, useState, useEffect } from 'react';
import { Card, Button, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useGetMyOrdersQuery } from '../slices/ordersApiSlice';
import Loader from '../components/Loader';
import Message from '../components/Message';
import OrderProduct from '../components/OrderProduct';
import ShopInfoModal from '../components/ShopInfoModal';
import { calculateDaysLeft } from '../utils/dateUtils';
import moment from 'moment';

const OrderHistoryScreen = () => {
    const { data: orders, isLoading, error, refetch } = useGetMyOrdersQuery();
    const [showModal, setShowModal] = useState(false);
    const [modalInfo, setModalInfo] = useState({ shopId: null, startDate: null, endDate: null, daysLeft: null, orderId: null, productId: null, productPrice: null });

    const unpaidOrders = useMemo(() => orders ? orders.filter(order => !order.isPaid) : [], [orders]);
    const rentalItems = useMemo(() => {
        if (!orders || !orders.length) return [];
        
        const items = [];
        orders.forEach(order => {
            if (order.RentalItems && order.RentalItems.length) { // RentalItems が存在するかチェック
                order.RentalItems.forEach(item => {
                    if (item.status === 'rented' && item.isDelivered && (item.rentalPeriod === 'unlimited' || calculateDaysLeft(item.endDate) > 3)) {
                        items.push({ orderId: order.id, ...item });
                    }
                });
            }
        });
        return items;
    }, [orders]);
    const overdueItems = useMemo(() => {
        if (!orders || !orders.length) return [];
        
        const items = [];
        orders.forEach(order => {
            if (order.RentalItems && order.RentalItems.length) { // RentalItems が存在するかチェック
                order.RentalItems.forEach(item => {
                    if (item.status === 'overdue' || (item.status === 'rented' && calculateDaysLeft(item.endDate) < 0 && item.rentalPeriod !== 'unlimited')) {
                        items.push({ orderId: order.id, ...item });
                    }
                });
            }
        });
        return items;
    }, [orders]);
    const nearDueDateItems = useMemo(() => {
        if (!orders || !orders.length) return [];

        const items = [];
        orders.forEach(order => {
            if (order.RentalItems && order.RentalItems.length) { // RentalItems が存在するかチェック
                order.RentalItems.forEach(item => {
                    if (item.status === 'rented' && 0 <= calculateDaysLeft(item.endDate) && calculateDaysLeft(item.endDate) <= 3 && item.rentalPeriod !== 'unlimited') {
                        items.push({ orderId: order.id, ...item });
                    }
                });
            }
        });
        return items;
    }, [orders]);
    const undeliveredItems = useMemo(() => {
        if (!orders || !orders.length) return [];
        
        const items = [];
        orders.forEach(order => {
            if (order.RentalItems && order.RentalItems.length) { // RentalItems が存在するかチェック
                order.RentalItems.forEach(item => {
                    if (!item.isDelivered) {
                        items.push({ orderId: order.id, ...item });
                    }
                });
            }
            if (order.PurchaseItems && order.PurchaseItems.length) { // PurchaseItems が存在するかチェック
                order.PurchaseItems.forEach(item => {
                    if (!item.isDelivered) {
                        items.push({ orderId: order.id, ...item });
                    }
                });
            }
        });

        // 店舗ごとに商品をまとめる
        const groupedItems = items.reduce((acc, item) => {
            const key = item.inStore ? item.shopId : 'delivery';
            if (!acc[key]) acc[key] = [];
            acc[key].push(item);
            return acc;
        }, {});

        return Object.entries(groupedItems).map(([key, items]) => ({
            shopId: key !== 'delivery' ? key : null,
            items,
        }));
    }, [orders]);

    const formatOrderDate = (date) => {
        return moment(date).format('YYYY年M月D日');
    };

    const handleShowModal = (shopId, startDate, endDate, daysLeft, orderId, productId, productPrice) => {
        setModalInfo({ shopId, startDate, endDate, daysLeft, orderId, productId, productPrice });
        setShowModal(true);
    };
    

    const handleCloseModal = () => {
        setShowModal(false);
        setModalInfo({ shopId: null, startDate: null, endDate: null, daysLeft: null, orderId: null, productId: null });
    };

    return (
        <Container>
            <div className="order-history-screen">
                <h1 className="my-4">注文履歴</h1>
                {isLoading ? <Loader /> :
                    error ? (
                        <Message variant="danger">{error?.data?.message || error.error}</Message>
                    ) : (
                        <>
                            {unpaidOrders.length > 0 && (
                                <>
                                    <h3 className='alert alert-danger mt-4'>お支払いが済んでないオーダー</h3>
                                    <div className='product-list'>
                                        {unpaidOrders.map((order) => (
                                            <li key={order.id}>
                                                <LinkContainer to={`/order/${order.id}`} style={{ cursor: 'pointer'}}>
                                                    <Card className="mb-3 order-card">
                                                        <Card.Header>
                                                            <small>注文日: {order.createdAt ? formatOrderDate(order.createdAt) : '未払い'}</small> <br />
                                                            <small>合計: ¥{order.totalPrice}</small>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <div className='product-list'>
                                                                {order.PurchaseItems && order.PurchaseItems.map((item) => (
                                                                    <li key={item.productId}>
                                                                        <OrderProduct product={item} />
                                                                    </li>
                                                                ))}
                                                                {order.RentalItems && order.RentalItems.map((item) => (
                                                                    <li key={item.productId}>
                                                                        <OrderProduct product={item} />
                                                                    </li>
                                                                ))}
                                                            </div>
                                                        </Card.Body>
                                                        <Card.Footer>
                                                            <LinkContainer to={`/order/${order.id}`}>
                                                                <Button variant="primary">支払う</Button>
                                                            </LinkContainer>
                                                        </Card.Footer>
                                                    </Card>
                                                </LinkContainer>
                                            </li>
                                        ))}
                                    </div>
                                </>
                            )}

                            {overdueItems.length > 0 && (
                                <>
                                    <h3 className='alert alert-danger mt-4'>期限切れの商品</h3>
                                    <div className='product-list'>
                                        {overdueItems.map((item) => (
                                            <li key={item.productId}>
                                                <Card className="mb-3 order-card">
                                                    <Card.Header>
                                                        <small>返却期限: {formatOrderDate(item.endDate)}</small>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <OrderProduct product={item} />
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => handleShowModal(item.shopId, item.startDate, item.endDate, calculateDaysLeft(item.endDate), item.orderId, item.productId, item.price)}
                                                        >
                                                            確認する
                                                        </Button>
                                                    </Card.Body>
                                                </Card>
                                            </li>
                                        ))}
                                    </div>
                                </>
                            )}

                            {undeliveredItems.length > 0 && (
                                <>
                                    <h3 className='alert alert-info mt-4'>まだ受け取っていない商品</h3>
                                    <div className='product-list'>
                                        {undeliveredItems.map((group) => (
                                            <Card className="mb-3 order-card">
                                                <Card.Header>
                                                    {group.shopId ? 'お店で受け取り' : '配送'}
                                                </Card.Header>
                                                <Card.Body>
                                                    <div className='product-list'>
                                                        {group.items.map((item) => (
                                                            <OrderProduct key={item.productId} product={item} />
                                                        ))}
                                                    </div>
                                                </Card.Body>
                                                <Card.Footer>
                                                    {group.shopId ? (
                                                        <Button variant="info" onClick={() => handleShowModal(group.shopId)}>
                                                            お店の情報
                                                        </Button>
                                                    ) : (
                                                        <LinkContainer to={`/order/${group.items[0].orderId}`}>
                                                            <Button variant="primary">詳細を見る</Button>
                                                        </LinkContainer>
                                                    )}
                                                </Card.Footer>
                                            </Card>
                                        ))}
                                    </div>
                                </>
                            )}

                            {nearDueDateItems.length > 0 && (
                                <>
                                    <h3 className='alert alert-warning mt-4'>返却期限が近い商品</h3>
                                    <div className='product-list'>
                                        {nearDueDateItems.map((item) => (
                                            <li key={item.productId}>
                                                <Card className="mb-3 order-card">
                                                    <Card.Header>
                                                        <small>返却期限: あと{calculateDaysLeft(item.endDate)}日</small>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <OrderProduct product={item} />
                                                        <Button
                                                            variant="warning"
                                                            onClick={() => handleShowModal(item.shopId, item.startDate, item.endDate, calculateDaysLeft(item.endDate), item.orderId, item.productId, item.price)}
                                                        >
                                                            確認する
                                                        </Button>
                                                    </Card.Body>
                                                </Card>
                                            </li>
                                        ))}
                                    </div>
                                </>
                            )}

                            {rentalItems.length > 0 && (
                                <>
                                    <h3 className='alert alert-warning mt-4'>レンタル中の商品</h3>
                                    <div className='product-list'>
                                        {rentalItems.map((item) => (
                                            <li key={item.productId}>
                                                <Card className="mb-3 order-card">
                                                    <Card.Header>
                                                        <small>返却期限: あと{calculateDaysLeft(item.endDate)}日</small>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <OrderProduct product={item} />
                                                        <Button
                                                            variant="warning"
                                                            onClick={() => handleShowModal(item.shopId, item.startDate, item.endDate, calculateDaysLeft(item.endDate), item.orderId, item.productId, item.price)}
                                                        >
                                                            確認する
                                                        </Button>
                                                    </Card.Body>
                                                </Card>
                                            </li>
                                        ))}
                                    </div>
                                </>
                            )}

                            {orders.length > 0 && (
                                <>
                                    <h3 className='alert alert-success mt-4'>全てのオーダー</h3>
                                    <div className='product-list'>
                                        {orders.map((order) => (
                                            <li key={order.id}>
                                                <LinkContainer to={`/order/${order.id}`} style={{ cursor: 'pointer'}}>
                                                    <Card className="mb-3 order-card">
                                                        <Card.Header>
                                                            <small>注文日: {order.paidAt ? formatOrderDate(order.paidAt) : '未設定'}</small> <br />
                                                            <small>合計: ¥{order.totalPrice}</small>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <div className='product-list'>
                                                                {order.PurchaseItems  && order.PurchaseItems.map((item) => (
                                                                    <li key={item.productId}>
                                                                        <OrderProduct product={item} />
                                                                    </li>
                                                                ))}
                                                                {order.RentalItems && order.RentalItems.map((item) => (
                                                                    <li key={item.productId}>
                                                                        <OrderProduct product={item} />
                                                                    </li>
                                                                ))}
                                                            </div>
                                                        </Card.Body>
                                                        <Card.Footer>
                                                            <LinkContainer to={`/order/${order.id}`}>
                                                                <Button variant="primary">詳細を見る</Button>
                                                            </LinkContainer>
                                                        </Card.Footer>
                                                    </Card>
                                                </LinkContainer>
                                            </li>
                                        ))}
                                    </div>
                                </>
                            )}
                        </>
                    )
                }

                <ShopInfoModal
                    show={showModal}
                    shopId={modalInfo.shopId}
                    startDate={modalInfo.startDate}
                    endDate={modalInfo.endDate}
                    daysLeft={modalInfo.daysLeft}
                    orderId={modalInfo.orderId}
                    productId={modalInfo.productId}
                    productPrice={modalInfo.productPrice}
                    onHide={handleCloseModal}
                />
            </div>
        </Container>
    );
};

export default OrderHistoryScreen;
