import { Link } from 'react-router-dom';

const GalleryItem = ({ product }) => {
  return (
    <div className='gallery-item rounded'>
        <Link className='gallery-item-img' to={`/product/${product.id}`}>
            <img src={product.image} variant='top' />
        </Link>

        <div className='gallery-item-body'>
            <b className='gallery-item-shop'>{ product.shopName }</b>
            <div className='gallery-item-name'>{product.name}</div>

            <div className='gallery-item-price-wrapper'>
                <div className='gallery-item-price'>
                    ¥ {product.price}
                </div>
            </div>
        </div>
    </div>
  )
}

export default GalleryItem