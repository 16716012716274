import { USERS_URL } from "../constants/constants";
import { apiSlice } from "./apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({  //builder.queryがGET、builder.mutationがPUT,POST,DELETE
            query: (data) => ({
                url: `${USERS_URL}/auth`,
                method: 'POST',
                body: data,
            }),
        }),
        register: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}`,
                method: 'POST',
                body: data,
            }),
        }),
        logout: builder.mutation({
            query: () =>({
                url: `${USERS_URL}/logout`,
                method: 'POST',
            }),
        }),
        getMyProfile: builder.query({
            query: () => ({
                url: `${USERS_URL}/profile`,
            }),
        }),
        verifyCurrentPassword: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/verify-password`,
                method: 'POST',
                body: data,
            }),
        }),
        updateProfile: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/profile`,
                method: 'PUT',
                body: data,
            }),
        }),
        getUsers: builder.query({
            query: () => ({
                url: `${USERS_URL}`,
            }),
            providesTags: ["Users"],
            keepUnusedDataFor: 5
        }),
        deleteUser: builder.mutation({
            query: (userId) => ({
                url: `${USERS_URL}/${userId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Users"],
        }),
        getUsersDetails: builder.query({
            query: (userId) => ({
                url: `${USERS_URL}/${userId}`,
            }),
            keepUnusedDataFor: 5
        }),
        updateUser: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/${data.userId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ["Users"], //Productsタグがついているデータをキャッシュから削除する
        }),
    }),
});

export const { 
    useLoginMutation, 
    useLogoutMutation, 
    useRegisterMutation, 
    useGetMyProfileQuery,
    useVerifyCurrentPasswordMutation,
    useUpdateProfileMutation,
    useGetUsersQuery,
    useDeleteUserMutation,
    useGetUsersDetailsQuery,
    useUpdateUserMutation,
} = usersApiSlice;