import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { CATEGORIES } from '../constants/categories';

const FavoriteProduct = React.memo(({ product, onDelete }) => {
    const handleDeleteClick = (e) => {
        e.stopPropagation(); // リンクのイベント伝播を停止
        onDelete(product.id);
    };
    return (
        <div className='product rounded'>
            <Link className='product-img' to={`/product/${product.id}`}>
                <img src={product.image} alt={product.name} loading='lazy' />
            </Link>

            <div className='product-body'>
                <b className='product-title'>{product.shopName}</b>
                <div className='product-caption'>{CATEGORIES[product.category]}</div>

                <div className='product-price-wrapper'>
                    <div className='product-price'>
                        ¥{product.price}
                    </div>
                    {
                        product.status === 'available' ? (
                            <Badge bg='success'>在庫あり</Badge>
                        ) : product.status === 'rented' ? (
                            <Badge bg='warning'>レンタル中</Badge>
                        ) : (
                            <Badge bg='danger'>売り切れ</Badge>
                        )
                    }
                </div>
            </div>
            {onDelete && (
                <Button
                    className='btn-sm product-delete' 
                    onClick={handleDeleteClick}
                >
                    <FaTimes />
                </Button>
            )}
        </div>
    );
});

export default FavoriteProduct;