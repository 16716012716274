import React, { useState } from 'react';
import { CATEGORIES, CATEGORY_MAPPING, PARENT_CATEGORIES_EN, CATEGORIES_EN } from '../constants/categories'; 
import { FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const CategoryPopup = ( {parentCategory} ) => {
    const [parentSelected, setParentSelected] = useState(false);
    const [popupClassName, setPopupClassName] = useState('category-popup');

    const childCategories = parentCategory.childrenCategories || [];
  
    const handleMouseEnter = () => {
      setParentSelected(true);
      setPopupClassName('category-popup');
      setTimeout(() => {
        setPopupClassName('category-popup-visible');
      }, 30);
    };
  
    const handleMouseLeave = () => {
      setPopupClassName('category-popup'); // ホバーが外れた瞬間にクラスを更新
      setTimeout(() => {
        // 一定時間後にポップアップ関連の状態をクリア
        setParentSelected(false);
      }, 200); // CSSのトランジションと同期するための遅延
    };

  return (
    <li
      className='parent-category'
      key={parentCategory.id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link
        to={`/category/${parentCategory.slug}`}
        className='parent-category-name'
      >
        {parentCategory.name}<FaChevronRight className='parent-category-chevron' />
      </Link>
      {parentSelected && (
        <div className={popupClassName}>
          <div className='child-category-wrapper'>
            <ul>
              {childCategories.map((childCategory) => (
                <li key={childCategory.id}>
                  <Link
                    to={`/category/${parentCategory.slug}/${childCategory.slug}`}
                    className='child-category'
                    onClick={handleMouseLeave}
                  >
                    {childCategory.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </li>
  );
};

export default CategoryPopup;
