import React from 'react';
import CardDisplay from './Displays/CardDisplay';
import GridDisplay from './Displays/GridDisplay';
import SearchResults from './SearchResults';
import MosaicTileDisplay from './Displays/MosaicTileDisplay';
import MagazineStyleDisplay from './Displays/MagazineStyleDisplay';

const ShopProductsDisplay = ({ displayStyle, productsData }) => {
    switch(displayStyle) {
        case 'card':
            return <CardDisplay productsData={productsData} />;
        case 'grid':
            return <GridDisplay productsData={productsData} />;
        case 'list':
            return <SearchResults productsData={productsData} />;
        case 'mosaic':
            return <MosaicTileDisplay productsData={productsData} />;
        case 'magazine':
            return <MagazineStyleDisplay productsData={productsData} />;
        default:
            return null;
    }
};

export default React.memo(ShopProductsDisplay);
