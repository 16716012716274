import { apiSlice } from './apiSlice';
import { CATEGORIES_URL } from '../constants/constants'; 

export const categoriesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCategories: builder.query({
            query: () => ({
                url: CATEGORIES_URL,
            }),
            providesTags: ['Categories'],
            keepUnusedDataFor: 300,
        }),
        getCategoryById: builder.query({
            query: (id) => `${CATEGORIES_URL}/${id}`,
        }),
        getCategoriesByParentId: builder.query({
            query: (parentId) => `${CATEGORIES_URL}/parent/${parentId}`,
        }),
        getParentCategories: builder.query({
            query: () => `${CATEGORIES_URL}/parents`,
        }),
        createCategory: builder.mutation({
            query: (newCategory) => ({
                url: CATEGORIES_URL,
                method: 'POST',
                body: newCategory,
            }),
        }),
        updateCategory: builder.mutation({
            query: ({ id, ...updatedCategory }) => ({
                url: `${CATEGORIES_URL}/${id}`,
                method: 'PUT',
                body: updatedCategory,
            }),
        }),
        deleteCategory: builder.mutation({
            query: (id) => ({
                url: `${CATEGORIES_URL}/${id}`,
                method: 'DELETE',
            }),
        }),
        getCategoryTree: builder.query({
            query: () => ({
                url: `${CATEGORIES_URL}/tree`,
            }),
            providesTags: ['Categories'],
            keepUnusedDataFor: 300,
        }),
    }),
});

// エンドポイントフックをエクスポート
export const { 
    useGetCategoriesQuery, 
    useGetCategoryByIdQuery, 
    useGetCategoriesByParentIdQuery, 
    useGetParentCategoriesQuery, 
    useCreateCategoryMutation, 
    useUpdateCategoryMutation, 
    useDeleteCategoryMutation,
    useGetCategoryTreeQuery,
} = categoriesApiSlice;
