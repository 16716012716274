import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { CATEGORIES } from '../../constants/categories';
import { Link } from 'react-router-dom';
import Paginate from '../Paginate';

const MosaicTileDisplay = ({ productsData }) => {
  const { products } = productsData;
  // 商品を22個ごとに分割する関数
  const chunkProducts = (products, size) =>
    Array.from({ length: Math.ceil(products.length / size) }, (v, i) =>
      products.slice(i * size, i * size + size)
    );

  // 商品を22個ごとにグループ分け
  const productGroups = chunkProducts(products, 22);

  return (
    <>
        {productGroups.map((group, groupIndex) => (
          <div key={groupIndex} className="mosaic-grid">
            {group.map((product, index) => (
              <div key={product.id} className={`grid-item mosaic-item-${index}`}>
                  <Link className='product-img' to={`/product/${product.id}`}>
                      <Image src={product.image} alt={product.name} fluid />
                      <div className="grid-info">
                          <b className='product-title'>{product.name}</b>
                          {/* <div className='product-caption'>{CATEGORIES[product.category]}</div> */}

                          <div className='product-price-wrapper'>
                              <div className='product-price'>
                                  ¥{product.price}
                              </div>
                          </div>
                      </div>
                  </Link>
              </div>
            ))}
          </div>
        ))}
      <Paginate
        pages={productsData.meta.pages}
        page={productsData.meta.page} 
      />
    </>
  );
};

export default MosaicTileDisplay;
