import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import store from './store';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/bootstrap.custom.css';
import './assets/styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/Route/AdminRoute';
import ShopRoute from './components/Route/ShopRoute';
import OrganizationRoute from './components/Route/OrganizationRoute';
import HomeScreen from './screens/HomeScreen';
import SearchScreen from './screens/SearchScreen';
import DetailedSearchScreen from './screens/DetailedSearchScreen';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import SubscriptionScreen from './screens/SubscriptionScreen';
import SubscriptionCheckoutScreen from './screens/SubscriptionCheckoutScreen';
import ShippingScreen from './screens/ShippingScreen';
import PaymentScreen from './screens/PaymentScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import ProfileScreen from './screens/ProfileScreen';
import OrderHistoryScreen from './screens/OrderHistoryScreen';
import FavoriteScreen from './screens/FavoriteScreen';
import UserApprovalRequestScreen from './screens/UserApprovalRequestScreen';
import NotificationScreen from './screens/NotificationScreen';

import ShopListScreen from './screens/ShopListScreen';
import ShopHomeScreen from './screens/ShopHomeScreen';

import OrderListScreen from './screens/admin/OrderListScreen';
import ProductListScreen from './screens/admin/ProductListScreen';
import ProductEditScreen from './screens/shop/ProductEditScreen';
import UserListScreen from './screens/admin/UserListScreen';
import UserEditScreen from './screens/admin/UserEditScreen';
import WithdrawalListScreen from './screens/admin/WithdrawalListScreen';
import AdminLoginScreen from './screens/admin/AdminLoginScreen';

import ShopLoginScreen from './screens/shop/ShopLoginScreen';
import ShopOrderListScreen from './screens/shop/ShopOrderListScreen';
import ShopProductListScreen from './screens/shop/ShopProductListScreen';
import ShopEditScreen from './screens/shop/ShopEditScreen';
import ShopOwnerProfileScreen from './screens/shop/ShopOwnerProfileScreen';

import TagListScreen from './screens/admin/TagListScreen';
import TagEditScreen from './screens/admin/TagEditScreen';
import BalanceScreen from './screens/shop/BalanceScreen';
import OrganizationLoginScreen from './screens/organization/OrganizationLoginScreen';
import OrganizationProfileScreen from './screens/organization/OrganizationProfileScreen';
import OrganizationMembersScreen from './screens/organization/OrganizationMembersScreen';
import AdminOrganizationScreen from './screens/organization/AdminOrganizationScreen';

import SubscriptionSuccessScreen from './screens/SubscriptionSuccessScreen';
import ShopManagementScreen from './screens/admin/ShopManagementScreen';
import ShopOwnerInfoScreen from './screens/admin/ShopOwnerInfoScreen';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_51OaA80HQ4620qDCA5uLgRH5kjNSFFh7StObsB36wZ4qyta8IafMd7OF5a1H6tSx2q3hVHBm69QAu8g6KFUKMKIGV00jneuZ889");


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route index={true} path='/' element={<HomeScreen />} />
      <Route path='/search' element={<SearchScreen />} />
      <Route path='/detail' element={<DetailedSearchScreen />} />
      <Route path='/category' element={<SearchScreen />} />
      <Route path='/category/:parentCategory' element={<SearchScreen />} />
      <Route path='/category/:parentCategory/:category' element={<SearchScreen />} />
      <Route path='/product/:id' element={<ProductScreen />} />
      <Route path='/cart' element={<CartScreen />} />
      <Route path='/login' element={<LoginScreen />} />
      <Route path='/shop/login' element={<ShopLoginScreen />} />
      <Route path='/organization/login' element={<OrganizationLoginScreen />} />
      <Route path='/register' element={<RegisterScreen />} />
      <Route path='/subscription' element={<SubscriptionScreen />} />
      <Route path='/subscription/:id/join' element={<SubscriptionCheckoutScreen />} />
      <Route path='/shops' element={<ShopListScreen />} />
      <Route path='/shop/:slug' element={<ShopHomeScreen />} />
      <Route path='/shop/:slug/about' element={<ShopHomeScreen />} />
      <Route path='/shop/:slug/contact' element={<ShopHomeScreen />} />
      <Route path='/notifications' element={<NotificationScreen />} />
      <Route path='/admin/login' element={<AdminLoginScreen />} />

      <Route  path='' element={<PrivateRoute />}>
        <Route  path='/shipping' element={<ShippingScreen />} />
        <Route  path='/payment' element={<PaymentScreen />} />
        <Route  path='/placeorder' element={<PlaceOrderScreen />} />
        <Route path='/order/:id' element={
          <Elements stripe={stripePromise}>
            <OrderScreen />
          </Elements>
        } />
        <Route  path='/profile' element={<ProfileScreen />} />
        <Route  path='/orderhistory' element={<OrderHistoryScreen />} />
        <Route  path='/favorites' element={<FavoriteScreen />} />
        <Route path='/user-approval-request/:id' element={<UserApprovalRequestScreen />} />
        <Route path='/subscription-success' element={<SubscriptionSuccessScreen />} />
      </Route>

      <Route  path='' element={<AdminRoute />}>
        <Route  path='/admin/orderlist' element={<OrderListScreen />} />
        <Route  path='/admin/productlist' element={<ProductListScreen />} />
        <Route  path='/admin/productlist/:pageNumber' element={<ProductListScreen />} />
        <Route  path='/admin/product/:id/edit' element={<ProductEditScreen />} />
        <Route  path='/admin/userlist' element={<UserListScreen />} />
        <Route  path='/admin/user/:id/edit' element={<UserEditScreen />} />
        <Route  path='/admin/taglist' element={<TagListScreen />} />
        <Route  path='/admin/tag/:id/edit' element={<TagEditScreen />} />
        <Route path='/admin/organizations' element={<AdminOrganizationScreen />} />
        <Route  path='/admin/withdrawals' element={<WithdrawalListScreen />} />
        <Route path='/admin/shops' element={<ShopManagementScreen />} />
        <Route path='/admin/shop-owner/:id' element={<ShopOwnerInfoScreen />} />
      </Route>

      <Route  path='' element={<ShopRoute />}>
        <Route  path='/shop/orderlist' element={<ShopOrderListScreen />} />
        <Route  path='/shop/productlist' element={<ShopProductListScreen />} />
        <Route  path='/shop/product/:id/edit' element={<ProductEditScreen />} />
        <Route  path='/shop/edit' element={<ShopEditScreen />} />
        <Route  path='/shop-owner/profile' element={<ShopOwnerProfileScreen />} />
        <Route  path='/shop/balance' element={<BalanceScreen />} />
      </Route>

      <Route  path='' element={<OrganizationRoute />}>
        <Route  path='/organization/profile' element={<OrganizationProfileScreen />} />
        <Route  path='/organization/members' element={<OrganizationMembersScreen />} />
      </Route>
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <PayPalScriptProvider deferLoading={ true }>
          <RouterProvider router={router} />
        </PayPalScriptProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();