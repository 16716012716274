import React from 'react';
import { Row, Col, Image, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CATEGORIES } from '../../constants/categories';
import Paginate from '../Paginate';

const MagazineStyleDisplay = ({ productsData }) => (
  <>
    <Row>
      {productsData.products.map(product => (
        <Col key={product.id} md={4}>
          <Card className="mb-4 d-flex">
              <Link to={`/product/${product.id}`} className='d-flex'>
                  <div className='product-img'>
                      <img src={product.image} alt={product.name} loading='lazy' />
                  </div>
                  {/* d-flexを追加し、align-items-centerを修正 */}
                  <div className='d-flex flex-column justify-content-center p-3' style={{ height: '168.2px' }}>
                      <b className='product-title'>{product.name}</b>
                      <div className='product-caption'>{CATEGORIES[product.category]}</div>
                      <div className='product-price-wrapper'>
                          <div className='product-price'>
                              ¥{product.price}
                          </div>
                      </div>
                  </div>
              </Link>
          </Card>
        </Col>
      ))}
    </Row>
    <Paginate
      pages={productsData.meta.pages}
      page={productsData.meta.page} 
    />
  </>
);

export default MagazineStyleDisplay;
