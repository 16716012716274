import React, { useMemo } from 'react';
import { GENDER_TO_ID } from '../../constants/productConstants';
import { useParams,  useLocation, useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetTagByIdQuery, useRemoveProductsFromTagMutation } from '../../slices/tagsApiSlice';
import { useGetAreasQuery } from '../../slices/areasApiSlice';
import { useGetProductsQuery } from '../../slices/productsApiSlice';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import GenderFilter from '../../components/GenderFilter';
import Tabs from '../../components/Tabs';
import ShopProduct from '../../components/ShopProduct';
import Paginate from '../../components/Paginate';
import { Container } from 'react-bootstrap';

const TagEditScreen = () => {
    const { id } = useParams(); // URLからタグIDを取得

    const { data: tag, isLoading, isError } = useGetTagByIdQuery(id);
    const { data: areasData, isLoading: areasLoading, error: areasError } = useGetAreasQuery();

    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const pageNumber = query.get('page') || 1;
    const selectedGender = query.get('gender') || 'all';
    const selectedArea = query.get('area') || 'all';

    const queryParams = {
        pageNumber,
        tagIds: [id],
        ...(selectedGender !== 'all' && { gender: GENDER_TO_ID[selectedGender] }),
        ...(selectedArea !== 'all' && { area: selectedArea }),
    };

    const { 
        data: productsData, 
        isLoading: loadingProducts, 
        error: errorProducts, 
        refetch 
    } = useGetProductsQuery(queryParams);

    const [removeProducts] = useRemoveProductsFromTagMutation();

    const deleteHandler = async (productId) => {
        if (window.confirm('本当にこの商品をタグから削除しますか？')) {
            try {
                await removeProducts({ id, products: [productId] }).unwrap();
                toast.success('タグからの商品の削除に成功しました');
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    const areaTabs = useMemo(() => {
        if (!areasData) return [];
        return [
            { label: 'すべて', value: 'all', ariaSelected: selectedArea === 'all' },
            ...areasData
                .filter(area => area.id !== 1) // 'その他' を除外（必要に応じてキーを調整）
                .map(area => ({
                    label: area.name,
                    value: String(area.id), // ID を文字列として設定
                    ariaSelected: selectedArea === String(area.id),
                }))
        ];
    }, [selectedArea, areasData]);

    const handleFilterChange = (type, value) => {
        if (value === 'all') {
            query.delete(type); // 'all'の場合、クエリパラメータを削除
        } else {
            query.set(type, value); // それ以外の場合、クエリパラメータを設定または更新
        }
        navigate(`${location.pathname}?${query.toString()}`);
    };

    if (isLoading) return <div>Loading...</div>;
    if (isError || !tag) return <div>Error loading tag</div>;

    return (
        <Container>
        <Link to='/admin/taglist' className='btn btn-light mb-4'>
            Go Back
        </Link> 
        {loadingProducts ? <Loader /> : errorProducts ? <Message variant='danger'>'{errorProducts.data?.message}'</Message> : (
            <>
            <div className='search-heading'>
                <div className='flex'>
                    <h1>
                        {tag.name}
                    </h1>
                    <div className='search-count'>
                        <span>{productsData.count}</span>件
                    </div>
                    <div className='search-page'>
                        {/* <span class="material-symbols-outlined">
                            apparel
                        </span> */}
                        <p>
                            {pageNumber}/{productsData.pages} ページ
                        </p>
                    </div>
                </div>
                <GenderFilter
                    selectedGender={selectedGender}
                    onClick={(value) => handleFilterChange('gender', value)}
                />
                <Tabs
                    tabs={areaTabs}
                    onClick={(value) => handleFilterChange('area', value)}
                />
            </div>
            <div className='product-list'>
                {productsData.products.length === 0 ? ('このタグに登録されている商品はまだありません') : productsData.products.map((product) => (
                    <li key={product.id}>
                        <ShopProduct product={product} onDelete={deleteHandler} isAdmin={true} />
                    </li>
                ))}
            </div>
            <Paginate
                    pages={productsData.pages}
                    page={productsData.page}
                />
            </>
        )}
        </Container>
    );
};

export default TagEditScreen;
