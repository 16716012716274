import { useState } from "react";
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormContainer from '../components/FormContainer';
import { saveShippingAddress } from "../slices/cartSlice";
import CheckoutSteps from "../components/CheckoutSteps";

const ShippingScreen = () => {
    const cart = useSelector((state) => state.cart);
    const { shippingAddress } = cart;

    const [postalCode, setPostalCode] = useState(shippingAddress?.postalCode || '');
    const [prefecture, setPrefecture] = useState(shippingAddress?.prefecture || '');
    const [city, setCity] = useState(shippingAddress?.city || '');
    const [streetAddress, setStreetAddress] = useState(shippingAddress?.streetAddress || '');
    const [buildingName, setBuildingName] = useState(shippingAddress?.buildingName || '');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(saveShippingAddress({ postalCode, prefecture, city, streetAddress, buildingName}));
        navigate('/payment');
    };

  return (
    <FormContainer>
        <CheckoutSteps step1 step2 />

        <h1>お届け先</h1>

        <Form onSubmit={submitHandler}>
            <Form.Group controlId="postalCode" className='my-2'>
                <Form.Label>郵便番号</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter postal code"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                >
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="prefecture" className='my-2'>
                <Form.Label>都道府県</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter prefecture"
                    value={prefecture}
                    onChange={(e) => setPrefecture(e.target.value)}
                >
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="city" className='my-2'>
                <Form.Label>市区町村</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                >
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="streetAddress" className='my-2'>
                <Form.Label>番地</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter street address"
                    value={streetAddress}
                    onChange={(e) => setStreetAddress(e.target.value)}
                >
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="buildingName" className='my-2'>
                <Form.Label>建物名</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter building name"
                    value={buildingName}
                    onChange={(e) => setBuildingName(e.target.value)}
                >
                </Form.Control>
            </Form.Group>

            <Button type="submit" variant="primary" className="my-2">
                Continue
            </Button>
        </Form>
    </FormContainer>
  )
}

export default ShippingScreen;