import React from 'react';
import { Link } from 'react-router-dom';

const Product = React.memo(({ product }) => {
    return (
        <div className='product rounded'>
            <Link className='product-img' to={`/product/${product.id}`}>
                <img src={product.image} alt={product.name} loading='lazy' />
            </Link>

            <div className='product-body'>
                <b className='product-title'>{product.shopName}</b>
                <div className='product-caption'>{product.category.name}</div>

                <div className='product-price-wrapper'>
                    <div className='product-price'>
                        ¥{product.price}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Product;