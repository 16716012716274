import { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Badge, Nav, Dropdown} from 'react-bootstrap';
import { FaShoppingCart, FaHeart, FaUser, FaBell } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLogoutMutation } from '../slices/usersApiSlice';
import { useGetUnreadNotificationsQuery } from '../slices/notificationsApiSlice';
import { logout } from '../slices/authSlice';
import SearchBox from './SearchBox';
import { resetCart } from '../slices/cartSlice';

export const Header = () => {
    const { cartItems } = useSelector((state) => state.cart);
    const { userInfo } = useSelector((state) => state.auth);
    const { data: notifications, refetch: refetchNotifications } = useGetUnreadNotificationsQuery({ role: userInfo?.role });

    const location = useLocation();
    const path = location.pathname;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [logoutApiCall] = useLogoutMutation();

    const logoutHandler = async () => {
        try {
            await logoutApiCall().unwrap();
            dispatch(logout());
            dispatch(resetCart());
            navigate('/login');
        } catch (err) {
            console.log(err);
        }
    }

    const handleMoreNotifications = () => {
        navigate('/notifications');
    };

    const handleNotificationClick = () => {
        if (!notifications || notifications.length === 0) {
            navigate('/notifications');
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getMonth() + 1}/${date.getDate()}`;
    };

    return (
        <header className='header'>
            <div className='header-wrapper' expand='md' collapseOnSelect>
                <LinkContainer to={userInfo?.role === 'shop' ? '/shop/orderlist' : '/'}>
                    <div className='logo'>
                        ふるぷら
                    </div>
                </LinkContainer>

                
                {path !== '/login' && path !== '/shoplogin' && userInfo?.role !== 'shop' && (
                    <div className="search-box-container">
                        <SearchBox />
                    </div>
                )}

                <div className='header-components'>
                    {userInfo && (
                        <Dropdown onToggle={handleNotificationClick}>
                            <Dropdown.Toggle className='header-fa-container header-notification'>
                                <FaBell className='header-fa' />
                                {notifications && notifications.length > 0 && (
                                    <Badge pill bg='danger' style={{ marginLeft: '2px' }}>
                                        {notifications.length}
                                    </Badge>
                                )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='header-notification-menu'>
                                {notifications && notifications.length > 0 && notifications.map((notification) => (
                                    <LinkContainer 
                                        to={
                                            notification.type === 'order' && userInfo.role === 'user' 
                                                ? `/order/${notification.orderId}`
                                            : notification.type === 'order'
                                                ? '/shop/productlist' 
                                            : ''
                                        }
                                    >
                                        <Dropdown.Item key={notification.id} className="d-flex align-items-center ">
                                            {!notification.read && <span className='notification-unread-dot'>●</span>}
                                            <small className="notification-item">{`${formatDate(notification.createdAt)}: ${notification.message}`}</small>
                                        </Dropdown.Item>
                                    </LinkContainer>
                                ))}
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={handleMoreNotifications}>
                                    <small>もっと見る</small>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                    { (!userInfo || userInfo?.role === 'user') && (
                        <LinkContainer className='header-fa-container' to='/cart'>
                            <div>
                                <FaShoppingCart className='header-fa' />
                                {
                                    cartItems.length > 0 && (
                                        <Badge pill bg='success' style={{marginLeft:'5px'}}>
                                            { cartItems.length}
                                        </Badge>
                                    )
                                }
                            </div>
                        </LinkContainer>
                    )}
                    { userInfo?.role === 'organization' ? (
                        <Dropdown>
                            <Dropdown.Toggle className='header-fa-container'>
                                <FaUser className='header-fa' />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <LinkContainer to='/organization/profile'>
                                    <Dropdown.Item>プロフィール</Dropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/organization/members'>
                                    <Dropdown.Item>メンバー</Dropdown.Item>
                                </LinkContainer>
                                <Dropdown.Item onClick={logoutHandler}>
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : userInfo?.role === 'user' ? (<>
                        <LinkContainer to='/favorites'>
                            <div className='header-fa-container'>
                                <FaHeart className='header-fa' />
                            </div>
                        </LinkContainer>
                        <Dropdown>
                            <Dropdown.Toggle className='header-fa-container'>
                                <FaUser className='header-fa' />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <LinkContainer to='/profile'>
                                    <Dropdown.Item>プロフィール</Dropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/orderhistory'>
                                    <Dropdown.Item>注文履歴</Dropdown.Item>
                                </LinkContainer>
                                <Dropdown.Item onClick={logoutHandler}>
                                    ログアウト
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </>) : userInfo ? (
                        <Dropdown>
                            <Dropdown.Toggle className='header-fa-container'>
                                <FaUser className='header-fa' />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <LinkContainer 
                                    to={userInfo.role === 'shop' ? `/shop-owner/profile` : '/admin/profile'}
                                >
                                    <Dropdown.Item>プロフィール</Dropdown.Item>
                                </LinkContainer>
                                <Dropdown.Item onClick={logoutHandler}>
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <LinkContainer to='/login'>
                            <Nav.Link><FaUser /> Sign In</Nav.Link>
                        </LinkContainer>
                    )}
                    {userInfo && userInfo.role === 'admin' && (
                        <Dropdown>
                            <Dropdown.Toggle className='header-fa-container header-admin'>
                                Admin
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <LinkContainer to='/admin/productlist'>
                                    <Dropdown.Item>商品</Dropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/admin/taglist'>
                                    <Dropdown.Item>タグ</Dropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/admin/userlist'>
                                    <Dropdown.Item>Users</Dropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/admin/orderlist'>
                                    <Dropdown.Item>Orders</Dropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/admin/shops'>
                                    <Dropdown.Item>ショップ</Dropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/admin/withdrawals'>
                                    <Dropdown.Item>引き出し申請</Dropdown.Item>
                                </LinkContainer>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}

                    {userInfo && userInfo.role === 'shop' && (
                        <Dropdown>
                            <Dropdown.Toggle className='header-fa-container header-admin'>
                                Shop
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <LinkContainer to='/shop/orderlist'>
                                    <Dropdown.Item>オーダー</Dropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/shop/productlist'>
                                    <Dropdown.Item>商品</Dropdown.Item>
                                </LinkContainer>
                                <LinkContainer to={`/shop/${userInfo.slug}`}>
                                    <Dropdown.Item>ホームページ</Dropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/shop/edit'>
                                    <Dropdown.Item>ショップ情報</Dropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='shop/balance'>
                                    <Dropdown.Item>お金管理</Dropdown.Item>
                                </LinkContainer>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>
            </div>
        </header>
    )
}
export default Header