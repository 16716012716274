import { useState, useEffect, useCallback } from "react";
import { Form, Button, Row, Col, ListGroup, Image, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import {
    useGetMyShopQuery,
    useUpdateShopProfileMutation,
    useUploadLogoMutation,
} from "../../slices/shopsApiSlice";
import { useGetAreasQuery } from "../../slices/areasApiSlice";

const ShopEditScreen = () => {
    const [isEditing, setIsEditing] = useState(false);

    const [googleMapsLinkError, setGoogleMapsLinkError] = useState("");
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

    const [updateProfile, { isLoading: loadingUpdateProfile } ] = useUpdateShopProfileMutation();

    const { data: shop, isLoading, error } = useGetMyShopQuery();
    const { data: areasData, isLoading: areasLoading, error: areasError } = useGetAreasQuery();

    const [uploadLogo, { isLoading: loadingUpload }] = useUploadLogoMutation();

    const [shopData, setShopData] = useState({
        name: '',
        areaId: 0,
        postalCode: '',
        prefecture: '',
        city: '',
        streetAddress: '',
        buildingName: '',
        phoneNumber: '',
        description: '',
        logo: '',
        openingHours: '',
        googleMapsLink: '',
    });

    useEffect(() => {
        if (shop) {
            setShopData({
                name: shop.name,
                areaId: shop.areaId,
                postalCode: shop.postalCode,
                prefecture: shop.prefecture,
                city: shop.city,
                streetAddress: shop.streetAddress,
                buildingName: shop.buildingName,
                phoneNumber: shop.phoneNumber,
                description: shop.description,
                logo: shop.logo,
                openingHours: shop.openingHours,
                googleMapsLink: shop.googleMapsLink,
            });
        }
    }, [shop]);

    const handleChange = useCallback(({ target: { name, value, checked, type } }) => {
        setShopData(prev => ({
            ...prev,
            [name]: type === 'checkbox' 
                ? (checked ? [...prev[name], Number(value)] : prev[name].filter(v => v !== Number(value))) 
                : value,
        }));
    }, []);    

    const handleCancel = useCallback(() => {
        setIsEditing(false);
        if (shop) {
            setShopData(prev => ({
                ...prev,
                name: shop.name,
                areaId: shop.areaId,
                postalCode: shop.postalCode,
                prefecture: shop.prefecture,
                city: shop.city,
                streetAddress: shop.streetAddress,
                buildingName: shop.buildingName,
                logo: shop.logo,
                phoneNumber: shop.phoneNumber,
                description: shop.description,
                openingHours: shop.openingHours,
                googleMapsLink: shop.googleMapsLink,
            }));
        }
    }, [shop]);

    const validateGoogleMapsLink = (link) => {
        if (!link) return true; // リンクが空の場合は無効としない
        const pattern = /^(https?:\/\/)?(www\.)?(google\.[a-z]+\/maps\/place\/.+|maps\.app\.goo\.gl\/.+)$/i;
        return pattern.test(link);
    };
    
    const handleGoogleMapsLinkBlur = () => {
        if (!shopData.googleMapsLink || validateGoogleMapsLink(shopData.googleMapsLink)) {
            setGoogleMapsLinkError('');
            setIsSubmitDisabled(false);
        } else {
            setGoogleMapsLinkError('無効なGoogleマップのリンクです');
            setIsSubmitDisabled(true);
        }
    };

    const submitShopHandler = async (e) => {
        e.preventDefault();
        if (!validateGoogleMapsLink(shopData.googleMapsLink)) {
            toast.error('無効なGoogleマップのリンクです');
            return;
        }
        try {
            const { logo, ...updateData } = shopData;
            const updatedShop = await updateProfile(updateData).unwrap();
            toast.success('ショップ情報が更新されました');
            setShopData({
                name: updatedShop.name || '',
                areaId: updatedShop.areaId || 0,
                postalCode: updatedShop.postalCode || '',
                prefecture: updatedShop.prefecture || '',
                city: updatedShop.city || '',
                streetAddress: updatedShop.streetAddress || '',
                buildingName: updatedShop.buildingName || '',
                phoneNumber: updatedShop.phoneNumber || '',
                description: updatedShop.description || '',
                logo: updatedShop.logo || '',
                openingHours: updatedShop.openingHours || '',
                googleMapsLink: updatedShop.googleMapsLink || '',
            });
            setIsEditing(false);
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
        formData.append('previousImage', shopData.logo);
        try {
            const res = await uploadLogo(formData).unwrap();
            toast.success(res.message);
            setShopData(prevShoptData => ({ ...prevShoptData, logo: res.logo }));
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

  return (
    <Container>
        <Row className="mt-4 d-flex justify-content-center">
            {isLoading ? (<h2>Loading...</h2>)
                : error ? (<Message variant='danger'> { error?.data?.message || error.error } </Message>) 
                : (<>
                <Col md={4}>
                    <h2>ショップ情報</h2>
                    {isEditing ? (
                        <Form variant='flush'>
                            <Form.Group controlId='name' className='my-2 me-5'>
                                <Form.Label>名前</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={shopData.name}
                                    onChange={handleChange} // handleChangeを使用
                                />
                            </Form.Group>
                            <Form.Group controlId='area' className='my-2 me-5'>
                                <Form.Label>エリア</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="areaId"
                                    value={shopData.areaId}
                                    onChange={handleChange} // handleChangeを使用
                                >
                                    <option value='0'>エリアを選択</option>
                                    {areasData.map(area => (
                                        <option key={area.id} value={area.id}>{area.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId='postalCode' className='my-2 me-5'>
                                <Form.Label>住所：郵便番号</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="postalCode"
                                    placeholder="郵便番号を入力"
                                    value={shopData.postalCode}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId='prefecture' className='my-2 me-5'>
                                <Form.Label>住所：都道府県</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="prefecture"
                                    placeholder="都道府県を入力"
                                    value={shopData.prefecture}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId='city' className='my-2 me-5'>
                                <Form.Label>住所：市区町村</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="city"
                                    placeholder="市町村を入力"
                                    value={shopData.city}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId='streetAddress' className='my-2 me-5'>
                                <Form.Label>住所：番地、部屋番号など</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="streetAddress"
                                    placeholder="番地、部屋番号などを入力"
                                    value={shopData.streetAddress}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId='buildingName' className='my-2 me-5'>
                                <Form.Label>住所：建物名</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="buildingName"
                                    placeholder="建物名を入力"
                                    value={shopData.buildingName}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId='phoneNumber' className='my-2 me-5'>
                                <Form.Label>電話番号</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phoneNumber"
                                    value={shopData.phoneNumber}
                                    onChange={handleChange} // handleChangeを使用
                                />
                            </Form.Group>
                            <Form.Group controlId='description' className='my-2 me-5'>
                                <Form.Label>説明</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="description"
                                    value={shopData.description}
                                    onChange={handleChange} // handleChangeを使用
                                />
                            </Form.Group>
                            <Form.Group controlId='openingHours' className='my-2 me-5'>
                                <Form.Label>営業時間</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="openingHours"
                                    value={shopData.openingHours}
                                    onChange={handleChange} // handleChangeを使用
                                />
                            </Form.Group>
                            <Form.Group controlId='googleMapsLink' className='my-2 me-5'>
                                <Form.Label>Googleマップリンク</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="googleMapsLink"
                                    value={shopData.googleMapsLink}
                                    onChange={handleChange}
                                    onBlur={handleGoogleMapsLinkBlur}
                                    isInvalid={!!googleMapsLinkError}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {googleMapsLinkError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button
                                type="submit"
                                variant="primary"
                                className="my-2"
                                onClick={submitShopHandler}
                            >
                                更新
                            </Button>
                            <Button
                                variant="light"
                                className="my-2 mx-2"
                                onClick={handleCancel}
                            >
                                キャンセル
                            </Button>
                            { loadingUpdateProfile && <Loader />}
                        </Form>
                    ) : (
                        <ListGroup variant='flush'>
                            <ListGroup.Item className="me-5">
                                <Row className='px-2'>
                                    <Col md={3}> <strong>名前:</strong> </Col>
                                    <Col className='text-end'> {shopData.name} </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item className="me-5">
                                <Row className='px-2'>
                                    <Col md={3}> <strong>エリア:</strong> </Col>
                                    <Col className='text-end'> {shopData.area.name} </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item className="me-5">
                                <Row className='px-2'>
                                    <Col md={3}> <strong>住所:</strong> </Col>
                                    <Col className='text-end'>
                                        〒{shopData.postalCode} <br />
                                        {shopData.prefecture}{shopData.city}{shopData.streetAddress}{shopData.buildingName}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item className="me-5">
                                <Row className='px-2'>
                                    <Col md={3}> <strong>電話番号:</strong> </Col>
                                    <Col className='text-end'> {shopData.phoneNumber} </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item className="me-5">
                                <Row className='px-2'>
                                    <Col md={3}> <strong>説明:</strong> </Col>
                                    <Col className='text-end'> {shopData.description} </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item className="me-5">
                                <Row className='px-2'>
                                    <Col md={3}> <strong>営業時間:</strong> </Col>
                                    <Col className='text-end'> {shopData.openingHours} </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item className="me-5">
                                <Row className='px-2'>
                                    <Col md={3}> <strong>Googleマップリンク:</strong> </Col>
                                    <Col className='text-end'> <a href={shopData.googleMapsLink} target="_blank" rel="noopener noreferrer">Googleマップ</a> </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Button
                                    variant='primary'
                                    className='my-2'
                                    onClick={() => setIsEditing(true)}
                                >
                                    編集
                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                    )}
                </Col>
                <Col md={4}>
                    <h2>ロゴ</h2>
                    <Image src={shopData.logo} alt={shopData.name} fluid />
                    {isEditing && (
                        <Form.Group controlId='image' className='my-2 mx-5'>
                            <Form.Label>画像</Form.Label>
                            <Form.Control 
                                type='text' 
                                placeholder='画像のurl' 
                                value={shopData.logo} 
                            ></Form.Control>
                            <Form.Control
                                type='file'
                                label='写真を選択する'
                                onChange={ uploadFileHandler }
                            ></Form.Control>
                        </Form.Group>
                    )}
                    {loadingUpload && <Loader />}
                </Col>
            </>)}
        </Row>
    </Container>
  )
}

export default ShopEditScreen;