import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useGetShopOwnerByIdQuery } from '../../slices/shopOwnersApiSlice';
import { Container, Row, Col, Table, Spinner, Alert, Button } from 'react-bootstrap';

const ShopOwnerInfoScreen = () => {
  const { shopId } = useParams();
  const { data: owners, isLoading, isError, error } = useGetShopOwnerByIdQuery(shopId);

  if (isLoading) return <Spinner animation="border" className="m-5" />;
  if (isError) return <Alert variant="danger" className="m-5">エラーが発生しました。</Alert>;

  return (
    <Container fluid>
      <Row className="mb-4">
        <Col>
          <h1>ショップオーナー情報</h1>
          <Button as={Link} to="/admin/shop-management" variant="secondary" className="mt-3">
            戻る
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {owners.length === 0 ? (
            <Alert variant="info">このショップにはオーナーが存在しません。</Alert>
          ) : (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>名前</th>
                  <th>メールアドレス</th>
                  <th>電話番号</th>
                  <th>アクション</th>
                </tr>
              </thead>
              <tbody>
                {owners.map((owner) => (
                  <tr key={owner.id}>
                    <td>{owner.id}</td>
                    <td>{owner.name}</td>
                    <td>{owner.email}</td>
                    <td>{owner.phoneNumber || '未登録'}</td>
                    <td>
                      <Button as={Link} to={`/admin/shop-owners/${owner.id}`} variant="info" size="sm" className="me-2">
                        詳細
                      </Button>
                      {/* 編集・削除ボタンもここに追加可能 */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ShopOwnerInfoScreen;