import React, { useState, useEffect, useMemo } from 'react';
import { Card, Button, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import OrderDetailsModal from '../../components/OrderDetailsModal';
import { useGetMyShopOrdersQuery } from '../../slices/ordersApiSlice';
import ShopProduct from '../../components/ShopProduct';

const ShopOrderListScreen = () => {
    const { data: ordersData, isLoading, error, refetch } = useGetMyShopOrdersQuery();
    const [showModal, setShowModal] = useState(false);
    const [activeOrder, setActiveOrder] = useState(null);
    const [activeProducts, setActiveProducts] = useState([]);
    const [isRental, setIsRental] = useState(false);

    const handleShowModal = (orderId, products, isRental) => {
        setActiveOrder(orderId);
        setActiveProducts(products);
        setIsRental(isRental);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setActiveOrder(null);
        setActiveProducts([]);
        setIsRental(false);
    };

    // 未配送の商品をグループ化
    const groupedUndelivered = useMemo(() => {
        const grouped = {};
        if (ordersData) {
        const { purchaseItems, rentalItems } = ordersData;

        purchaseItems.forEach(item => {
            if (!item.isDelivered) {
            const orderId = item.orderId;
            if (!grouped[orderId]) {
                grouped[orderId] = [];
            }
            grouped[orderId].push({ ...item, serviceOption: 'purchase' });
            }
        });

        rentalItems.forEach(item => {
            if (!item.isDelivered) {
            const orderId = item.orderId;
            if (!grouped[orderId]) {
                grouped[orderId] = [];
            }
            grouped[orderId].push({ ...item, serviceOption: 'rental' });
            }
        });
        }
        return grouped;
    }, [ordersData]);

    // レンタル中の商品を取得
    const rentedItems = useMemo(() => {
        const items = [];
        if (ordersData) {
        const { rentalItems } = ordersData;
        rentalItems.forEach(item => {
            if (item.status === 'rented' && item.isDelivered) {
            items.push({ ...item, serviceOption: 'rental' });
            }
        });
        }
        return items;
    }, [ordersData]);

    // その他の商品を取得
    const otherItems = useMemo(() => {
        const items = [];
        if (ordersData) {
        const { purchaseItems, rentalItems } = ordersData;
        purchaseItems.forEach(item => {
            if (item.isDelivered) {
            items.push({ ...item, serviceOption: 'purchase' });
            }
        });
        rentalItems.forEach(item => {
            if (item.status !== 'rented' && item.isDelivered) {
            items.push({ ...item, serviceOption: 'rental' });
            }
        });
        }
        return items;
    }, [ordersData]);

    return (
        <Container>
            <h1 className='mt-4'>注文管理ページ</h1>

            <OrderDetailsModal
                key={activeOrder}
                show={showModal}
                onHide={handleCloseModal}
                order={activeOrder}
                products={activeProducts}
                isRental={isRental}
                refetch={refetch}
            />

            {isLoading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error?.data?.message || error.error}</Message>
            ) : (
                <>
                {/* 未配送の商品 */}
                {Object.keys(groupedUndelivered).length > 0 && (
                    <>
                    <h3 className='alert alert-danger mt-4'>未配送の商品</h3>
                    <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                        {Object.entries(groupedUndelivered).map(([orderId, products]) => (
                            <Card key={orderId} className="mb-3" style={{ maxWidth: '600px', width: 'auto' }}>
                                <Card.Header style={{ cursor: 'pointer' }} onClick={() => handleShowModal(orderId, products, false)}>
                                    オーダー {orderId.substring(0, 8)}...
                                </Card.Header>
                                <Card.Body>
                                    <div className='product-list'>
                                        {products.map((product) => (
                                            <li key={product.id}>
                                                <ShopProduct product={product} />
                                            </li>
                                        ))}
                                    </div>
                                    <Button variant="primary" onClick={(e) => { e.stopPropagation(); handleShowModal(orderId, products, false); }}>
                                        配送先を表示
                                    </Button>
                                </Card.Body>
                            </Card>
                        ))}
                    </div>
                    </>
                )}

                {/* レンタル中の商品 */}
                {rentedItems.length > 0 && (
                    <>
                    <h3 className='alert alert-warning mt-4'>レンタル中の商品</h3>
                    <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                        {rentedItems.map((product) => (
                            <Card key={product.id} className="mb-3" style={{ maxWidth: '600px', width: 'auto' }}>
                                <Card.Header style={{ cursor: 'pointer' }} onClick={() => handleShowModal(product.orderId, [product], true)}>
                                    商品名: {product.name}
                                </Card.Header>
                                <Card.Body>
                                    <div className='product-list'>
                                        <li key={product.id}>
                                            <ShopProduct product={product} />
                                        </li>
                                    </div>
                                    <Button variant="primary" onClick={() => handleShowModal(product.orderId, [product], true)}>
                                        レンタル情報
                                    </Button>
                                </Card.Body>
                            </Card>
                        ))}
                    </div>
                    </>
                )}

                {/* その他の商品 */}
                {otherItems.length > 0 && (
                    <>
                    <h3 className='alert alert-success mt-4'>その他の商品</h3>
                    <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                        {otherItems.map((product) => (
                            <Card key={product.id} className="mb-3" style={{ maxWidth: '600px', width: 'auto' }}>
                                <Card.Header>
                                    商品名: {product.name}
                                </Card.Header>
                                <Card.Body>
                                    <div className='product-list'>
                                        <li key={product.id}>
                                            <ShopProduct product={product} />
                                        </li>
                                    </div>
                                </Card.Body>
                            </Card>
                        ))}
                    </div>
                    </>
                )}
                </>
            )}
        </Container>
    );
};

export default ShopOrderListScreen;