import { useState } from "react";
import { 
    useGetAllWithdrawalsQuery, 
    useCompleteWithdrawalMutation, 
    useRejectWithdrawalMutation 
} from "../../slices/balanceApiSlice";
import { Table, Button, Alert, Container, Badge } from 'react-bootstrap';
import { toast } from 'react-toastify';
import WithdrawalModal from "../../components/WithdrawalModal";

const WithdrawalListScreen = () => {
    const { data: withdrawals, isLoading, error } = useGetAllWithdrawalsQuery();
    const [completeWithdrawal, { isLoading: isUpdatingToCompleted }] = useCompleteWithdrawalMutation();
    const [rejectWithdrawal, { isLoading: isUpdatingToRejected }] = useRejectWithdrawalMutation();
    const [selectedWithdrawal, setSelectedWithdrawal] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = (withdrawal) => {
        setSelectedWithdrawal(withdrawal);
        setShowModal(true);
    };

    const handleComplete = async () => {
        try {
            await completeWithdrawal({ id: selectedWithdrawal.id }).unwrap();
            toast.success('振り込みが完了しました。');
        } catch (error) {
            toast.error(`振り込みの完了に失敗しました: ${error.data?.message || error.message}`);
        }
        handleCloseModal();
    };

    const handleReject = async () => {
        try {
            await rejectWithdrawal({ id: selectedWithdrawal.id }).unwrap();
            toast.success('振り込み拒否が完了しました。');
        } catch (error) {
            toast.error(`振り込みの失敗に失敗しました: ${error.data?.message || error.message}`);
        }
        handleCloseModal();
    };

    if (isLoading) return <p>Loading...</p>;
    if (error) return <Alert variant="danger">Error: {error.data?.message || error.message}</Alert>;

    return (
        <Container>
            <h1>全ショップの引き出し申請管理</h1>
            <Table striped bordered hover responsive className="table-sm">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>ショップID</th>
                        <th>金額</th>
                        <th>状態</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody>
                    {withdrawals?.map((withdrawal) => (
                        <tr key={withdrawal.id}>
                            <td>{withdrawal.id}</td>
                            <td>{withdrawal.shopId}</td>
                            <td>{withdrawal.amount}</td>
                            <td>
                                {withdrawal.status === "pending" ? <Badge bg="warning">保留中</Badge>
                                    : withdrawal.status === "completed" ? <Badge bg="success">完了</Badge>
                                    : <Badge bg="danger">拒否</Badge>
                                }
                            </td>
                            <td>
                                <Button
                                    variant="primary"
                                    onClick={() => handleShowModal(withdrawal)}
                                    disabled={withdrawal.status !== "pending" ||  isUpdatingToCompleted || isUpdatingToRejected}
                                >
                                    詳細
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {selectedWithdrawal && (
                <WithdrawalModal
                    show={showModal}
                    handleClose={handleCloseModal}
                    withdrawal={selectedWithdrawal}
                    onComplete={handleComplete}
                    onReject={handleReject}
                />
            )}
        </Container>
    );
};

export default WithdrawalListScreen;
