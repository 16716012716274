import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { FaEdit, FaTrash, FaBluetoothB } from 'react-icons/fa';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import Tabs from '../../components/Tabs';
import ShopProduct from '../../components/ShopProduct';
import { toast } from 'react-toastify';
import { 
    useGetMyShopProductsQuery,
    useCreateProductMutation,
    useDeleteProductMutation,
} from '../../slices/productsApiSlice';
import {
    requestAndStoreBluetoothDeviceName,
    getStoredBluetoothDeviceName,
    getStoredSerialPortIdentifier,
    connectToBLEDevice,
    chooseAndConnectToBLEDevice,
    setImage, 
    printImage 
} from '../../utils/phomemoPrinter.ts';

const ShopProductListScreen = () => {
    const navigate = useNavigate();

    const { data: products, isLoading, error, refetch } = useGetMyShopProductsQuery();
    const [createProduct, { isLoading: loadingCreate }] = useCreateProductMutation();
    const [deleteProduct, { isLoading: loadingDelete }] = useDeleteProductMutation();

    const [selectedStatus, setSelectedStatus] = useState('available');

    const [serialPortIdentifier, setSerialPortIdentifier] = useState('');
    const [bluetoothDeviceName, setBluetoothDeviceName] = useState('');

    useEffect(() => {
        const storedSerialPortIdentifier = getStoredSerialPortIdentifier();
        if (storedSerialPortIdentifier) {
            setSerialPortIdentifier(storedSerialPortIdentifier);
        }
        const storedBluetoothDeviceName = getStoredBluetoothDeviceName();
        if (storedBluetoothDeviceName) {
            setBluetoothDeviceName(storedBluetoothDeviceName);
        }
    }, []);

    const deleteHandler = async (id) => {
        if (window.confirm('本当にこの商品を削除しますか？')) {
            try {
                await deleteProduct(id);
                toast.success('商品の削除に成功しました');
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    const handleChangeBluetoothDevice = async () => {
        try {
            const newDeviceName = await requestAndStoreBluetoothDeviceName();
            setBluetoothDeviceName(newDeviceName);
            toast.success('Bluetooth登録プリンターが更新されました');
        } catch (error) {
            console.error('Error changing Bluetooth device:', error);
            toast.error('Bluetooth登録プリンターの更新に失敗しました');
        }
    };

    const handleDeleteBluetoothDevice = async () => {
        try {
            setBluetoothDeviceName('');
            localStorage.removeItem('bluetoothDeviceName');
            toast.success('Bluetooth登録プリンターが削除されました');
        } catch (error) {
            console.error('Error deleting Bluetooth device:', error);
            toast.error('Bluetooth登録プリンターの削除に失敗しました');
        }
    };

    async function ensureBluetoothConnection() {
        if (!bluetoothDeviceName) {
            const userConfirmed = window.confirm('Bluetoothデバイス名が未設定です。新しいデバイスを選択しますか？');
            if (!userConfirmed) return false;
    
            const newDeviceName = await chooseAndConnectToBLEDevice();
            if (!newDeviceName) return false;
    
            setBluetoothDeviceName(newDeviceName);
            localStorage.setItem('bluetoothDeviceName', newDeviceName);
        } else {
            const connected = await connectToBLEDevice(bluetoothDeviceName);
            if (!connected) return false;
        }
        return true;
    }

    const createProductHandler = async () => {
        try {
            // Bluetoothに接続する
            const connected = await ensureBluetoothConnection();
            if (!connected) {
                toast.error('Bluetooth登録プリンターへの接続に失敗しました');
                return;
            }
    
            if (window.confirm('新しい商品を作成しますか？')) {
                const newProduct = await createProduct().unwrap(); // 新しい商品を作成
                toast.success('商品が作成されました。QRコードを印刷します');
    
                // QRコードを生成して印刷
                const qrCodeUrl = `https://www.furupura.jp/product/${newProduct.id}`;
                const imageSet = await setImage(qrCodeUrl);
                if (!imageSet) {
                    toast.error('QRコードの設定に失敗しました');
                    return;
                }
                const printed = await printImage('bluetooth');
                if (!printed) {
                    toast.error('QRコードの印刷に失敗しました');
                } else {
                    toast.success('QRコードを印刷しました。商品タグに貼り付けてください');
                }
    
                // 編集画面に遷移
                refetch();
                navigate(`/shop/product/${newProduct.id}/edit`);
            }
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };
    
    const tabsHandler = (value) => {
        setSelectedStatus(value);
    };

    const statusTabs = useMemo(() => [
        { label: '在庫', value: 'available', ariaSelected: selectedStatus === 'available' },
        { label: 'レンタル中', value: 'rented', ariaSelected: selectedStatus === 'rented' },
        { label: '売り切れ', value: 'sold', ariaSelected: selectedStatus === 'sold' },
    ], [selectedStatus]);

    const filteredProducts = useMemo(() => {
        return products ? products.filter(product => product.status === selectedStatus) : [];
    }, [products, selectedStatus]);

    return (
        <Container>
            <h1 className='mt-4'>商品管理ページ</h1>
    
            {loadingCreate && <Loader />}
            {loadingDelete && <Loader />}


            <h3 className='alert alert-success mt-4'>すべての商品</h3>

            <Card className='mt-2 d-inline-block w-auto'>
                <Card.Body>
                    <Row>
                        <Col md='auto'>
                            <FaBluetoothB className='mb-1'/> <strong>Bluetoothプリンター：</strong> <br />
                            {bluetoothDeviceName || '未設定'}
                        </Col>
                        <Col className='d-flex align-items-center'>
                            <Button className='btn-sm me-1' variant="secondary" onClick={handleChangeBluetoothDevice}>
                                <FaEdit color='white' />
                            </Button>
                            <Button className='btn-sm' variant="danger" onClick={handleDeleteBluetoothDevice}>
                                <FaTrash color='white' />
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Row className='mt-3'>
                <Col>
                    <Button className='btn-sm' onClick={createProductHandler}>
                        <FaEdit /> 商品を追加
                    </Button>
                </Col>
            </Row>

            <Tabs
                tabs={statusTabs}
                onClick={(value) => tabsHandler(value)}
            />
    
            {isLoading ? <Loader /> : error ? <Message variant='danger'>{error.data?.message}</Message> : (
                <div className='product-list'>
                    {filteredProducts.map((product) => (
                        <li key={product.id}>
                            <ShopProduct product={product} onDelete={deleteHandler} />
                        </li>
                    ))}
                </div>
            )}
        </Container>
    )
    
}

export default ShopProductListScreen;