import { useRef, useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight, FaAngleDoubleLeft } from 'react-icons/fa';
import GalleryItem from './GalleryItem';

const GalleryDisplay = ({ products }) => {
  const galleryRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [canScroll, setCanScroll] = useState(false);

  useEffect(() => {
    const checkScrollButtons = () => {
      if (galleryRef.current) {
        const gallery = galleryRef.current;
        const isScrollable = gallery.scrollWidth > gallery.clientWidth;
        setCanScrollLeft(gallery.scrollLeft > 0);
        setCanScrollRight(gallery.scrollLeft < gallery.scrollWidth - gallery.clientWidth);
        setCanScroll(isScrollable);
      }
    };

    checkScrollButtons();
    const gallery = galleryRef.current;
    if (gallery) {
      gallery.addEventListener('scroll', checkScrollButtons);
    }

    return () => {
      if (gallery) {
        gallery.removeEventListener('scroll', checkScrollButtons);
      }
    };
  }, [products]);

  const scroll = (direction) => {
    const gallery = galleryRef.current;
    if (direction === 'right') {
      const scrollAmount = gallery.offsetWidth;
      gallery.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    } else if (direction === 'left') {
      const scrollAmount = -gallery.offsetWidth;
      gallery.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    } else if (direction === 'double-left') {
      gallery.scrollTo({ left: 0, behavior: 'smooth' });
    }
  };

  return (
    <div className="product-gallery-wrapper">
        <div className="product-gallery" ref={galleryRef}>
          {products.map((product) => (
              <GalleryItem key={product.id} product={product} />
          ))}
        </div>
        {canScroll && canScrollLeft && (
          <button onClick={() => scroll('left')} className="scroll-button scroll-left">
            <FaChevronLeft className='scroll-svg' />
          </button>
        )}
        {canScroll && canScrollRight ? (
          <button onClick={() => scroll('right')} className="scroll-button scroll-right">
            <FaChevronRight className='scroll-svg' />
          </button>
        ) : canScroll && !canScrollRight && (
          <button onClick={() => scroll('double-left')} className="scroll-button scroll-double-left">
            <FaAngleDoubleLeft className='scroll-svg' />
          </button>
        )}
    </div>
  );
};

export default GalleryDisplay;
