import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CATEGORIES } from '../../constants/categories';
import Paginate from '../Paginate';

const CardDisplay = ({ productsData }) => (
    <>
        <div className='grid'>
            {productsData.products.map(product => (
                <div>
                    <div className='grid-item' key={product.id} md={4} lg={3}>
                        <Link className='product-img' to={`/product/${product.id}`}>
                            <Image src={product.image} alt={product.name} fluid />
                        </Link>
                    </div>
                    <div className='mb-2 my-2'>
                        <Link className='product-img' to={`/product/${product.id}`}>
                            <b className='product-title'>{product.name}</b>
                            {/* <div className='product-caption'>{CATEGORIES[product.category]}</div> */}
                            <div className='product-price-wrapper'>
                                <div className='product-price'>
                                    ¥{product.price}
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            
            ))}
        </div>
        <Paginate
            pages={productsData.meta.pages}
            page={productsData.meta.page}
        />
    </>
);

export default CardDisplay;